import { Injectable } from '@angular/core';

export interface CertificateModalSignatureData {
  salt?: string;
  signature?: SignedDocuments;
  doc?: DocumentsToSign;
  type?: 'SIGN' | 'REG';
}

export type SignedDocuments = Record<string, Base64String>;
export type DocumentsToSign = Record<string, string>;
export type Base64String = string;

export interface NcaLayerResponse { // флаг, определяет успешно ли была выполнена операция;
  status: boolean; // объект, результат выполнения, в случае отмены выполнения операции пользователем он пуст ({}), иначе содержит:
  body: {
    /**
     * подпись в одном из следующих форматов:
     *  если "format": "xml", то XML подпись в виде строки;
     *  если "format": "cms", то CMS подпись в виде PEM строки;
     */
    result?: string;
  }; // код возврата, присутствует только в случае ошибки ("status": false);
  code?: unknown; // подробности о возникшей ошибке, присутствует только в случае ошибки ("status": false);
  details?: unknown; // строка с описанием ошибки, присутствует только в случае ошибки ("status": false).
  message?: unknown;
}

export type CertificateActionType = 'AUTHENTICATION' | 'SIGNATURE';

type callbackFn = ((args: NcaLayerResponse) => void);

@Injectable({
  providedIn: 'root',
})
export class SignatureNcaCommonUtilsService {
  callback?: callbackFn;
  keyPurpose: CertificateActionType = 'SIGNATURE';

  webSocket?: WebSocket;

  constructor() {
    this.webSocket = new WebSocket('wss://localhost:13579/'); // Do we need this shit if there is method above?
    this.waitForSocketConnection();
  }

  isNcaEnabled(): boolean {
    if (this.webSocket?.readyState !== 1) return false;

    this.webSocket.onmessage = (event: MessageEvent) => {
      const result: NcaLayerResponse = JSON.parse(event.data);
      if (result.body?.result) {
        /**
         * До NCALayer 1.3 в ответе приходила строка. Сейчас приходит массив. Для обратной совместимости обрабатываем оба варианта ответа
         */
        const signingData = Array.isArray(result.body.result)
          ? result.body.result[0]
          : result.body.result;
        result.body.result = signingData
          .replace('-----BEGIN CMS-----', '')
          .replace('-----END CMS-----', '')
          .replace(/(?:\\[rn]|[\r\n]+)+/g, '');
      }

      if (this.callback != null) {
        this.callback(result);
      }
    };
    return true;
  }

  getKeyPurpose(): CertificateActionType {
    return this.keyPurpose;
  }

  setKeyPurpose(actionType: CertificateActionType): void {
    this.keyPurpose = actionType;
  }

  createCMSSignatureFromBase64(base64ToSign: string, callBack: callbackFn): void {
    const oids = this.getKeyPurpose() === 'AUTHENTICATION'
      ? '1.3.6.1.5.5.7.3.2'
      : '1.3.6.1.5.5.7.3.4';

    const createCMSSignatureFromBase64 = {
      module: 'kz.gov.pki.knca.basics',
      method: 'sign',
      args: {
        allowedStorages: [
          'PKCS12',
          'AKKaztokenStore',
          'AKKZIDCardStore',
          'AKEToken72KStore',
          'AKJaCartaStore',
        ],
        format: 'cms',
        data: base64ToSign,
        signingParams: {
          decode: true,
          encapsulate: false,
        },
        signerParams: {
          extKeyUsageOids: [
            oids,
          ],
          chain: [],
        },
        locale: 'ru',
      },
    };
    this.callback = callBack;
    this.webSocket?.send(JSON.stringify(createCMSSignatureFromBase64));
  }

  private waitForSocketConnection(): void {
    setTimeout(() => {
      if (this.webSocket && this.webSocket.readyState === 1) {
        return;
      }
      this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
      this.waitForSocketConnection();
    }, 2000);
  }
}
