import { NgModule } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { MetricsService } from './metrics.service';
import { SettingsService } from '../../core/services/settings.service';

export let YM_COUNTER_ID: string;

declare global {
  interface Window { ym: any }
}

@NgModule({
  providers: [
    MetricsService,
  ],
})
export class MetricsModule {
  constructor(
    private router: Router,
    private location: Location,
    private settingsService: SettingsService,
    private window: Window,
  ) {
    this.initGtm();
    settingsService.getSettings()
      .pipe(take(1))
      .subscribe((settings) => {
        YM_COUNTER_ID = settings['ui.options.metrics.yandex.counterId'];
        this.initYandexMetrika();
      });
  }

  private initYandexMetrika() {
    // @ts-ignore
    (function (m, e, t, r, i, k, a) {
      // @ts-ignore
      m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
      // @ts-ignore
      m[i].l = Number(new Date());
      for (let j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
      // @ts-ignore
      k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
    }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));

    this.window.ym(YM_COUNTER_ID, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });

    this.pageOpenEvent();
  }

  private initGtm(): void {
    (function (w, d, s, l, i) { // @ts-ignore
      w[l] = w[l] || []; w[l].push(
        {
          'gtm.start': new Date().getTime(), event: 'gtm.js',
        },
      ); const f = d.getElementsByTagName(s)[0];// @ts-ignore
      const j = d.createElement(s); const dl = l != 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer', 'GTM-5GKMHRCC'));
  }

  private pageOpenEvent() {
    let prevPath = this.location.path();
    this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    )
      .subscribe(() => {
        const newPath = this.location.path();
        this.window.ym(YM_COUNTER_ID, 'hit', newPath, {
          referer: prevPath,
        });
        prevPath = newPath;
      });
  }
}
