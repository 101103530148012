import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { AlertsService, IAlert } from '../../../core/services/alerts.service';

@Component({
  selector: 'tax-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertsComponent implements OnInit, OnDestroy {
  alerts: IAlert[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
  ) {}

  ngOnInit(): void {
    this.alertsService.alerts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((alerts) => {
        this.alerts = alerts;
        this.changeDetectorRef.markForCheck();
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  close(alertIndex: number): void {
    this.alertsService.closeAlert(alertIndex);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
