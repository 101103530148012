import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../../location/location.service';

@Component({
  selector: 'tax-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.styl'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
  @Input() set pageIndex(pageIndex: number) {
    this.setPage(pageIndex);
  }

  @Input() total: any;

  @Output() nextPageEvent = new EventEmitter<number>();
  @Output() previousPageEvent = new EventEmitter<number>();

  _pageIndex: number = 1;

  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.page) {
      this._pageIndex = Number(this.route.snapshot.queryParams.page);
    }
  }

  ngOnDestroy(): void {
    this.setPage(null);
  }

  nextPage(): void {
    this._pageIndex += 1;
    this.setPage(this._pageIndex);
    this.nextPageEvent.emit(this._pageIndex);
  }

  previousPage(): void {
    this._pageIndex -= 1;
    this.setPage(this._pageIndex);
    this.previousPageEvent.emit(this._pageIndex);
  }

  private setPage(pageIndex: number | null): void {
    this.locationService.navigate(
      [],
      {
        queryParams: {
          page: pageIndex,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      },
    );
  }
}
