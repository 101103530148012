import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SmsCodeSendResponse, SmsSendNewPhone } from './confirm-by-sms.model';
import { showErrorAlert } from '../../alerts/alert.decorator';
import { errorPrefix } from '../../../../core/errors-interceptor';
import { LoginBySmsFormValue } from '../../../../system/auth/landing-shared/login-by-sms/login-by-sms-form.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmBySmsService {
  MAX_ATTEMPTS_COUNT: number = 3;
  private INITIAL_TIME: number = 5 * 60;

  attemptCount$: Subject<number> = new ReplaySubject(1);
  private attemptCount = this.MAX_ATTEMPTS_COUNT;

  countdown$: Observable<string> = timer(0, 1000)
    .pipe(
      take(this.INITIAL_TIME + 1),
      map((value) => {
        if (value === 0) {
        // TODO: try use defer instead this
          this.attemptCount$.next(--this.attemptCount);
        }
        return this.INITIAL_TIME - value;
      }),
      map(this.timeFormat),
    );

  constructor(
    private httpClient: HttpClient,
  ) {
    this.attemptCount$.next(this.MAX_ATTEMPTS_COUNT);
  }

  sendPasswordToNewPhone(payload: SmsSendNewPhone): Observable<SmsCodeSendResponse> {
    return this.httpClient.post<SmsCodeSendResponse>('/api/user/sms/send-new-phone', {
      ...payload,
    }, {
      context: errorPrefix('sms.code.send.error'),
    });
  }

  @showErrorAlert()
  sendPasswordToUserPhone(payload: LoginBySmsFormValue): Observable<SmsCodeSendResponse> {
    const request: Observable<SmsCodeSendResponse> = this.httpClient.post<SmsCodeSendResponse>('/api/sms/sendLoginCode', payload, {
      context: errorPrefix('sms.code.send.error'),
    });

    return this.smsCodeSendRequest(request);
  }

  private smsCodeSendRequest(request: Observable<any>): Observable<SmsCodeSendResponse> {
    return request.pipe(
      tap((result) => {
        if (!result.success) {
          return;
        }

        if (result.params?.resendCount) {
          this.attemptCount$.next(result.params.resendCount);
        }
      }),
    );
  }

  private timeFormat(seconds: number): string {
    const secondsText: number = seconds % 60;
    const secondsPrefix: string = secondsText < 10 ? '0' : '';
    return `${Math.floor(seconds / 60)}:${secondsPrefix}${secondsText}`;
  }
}
