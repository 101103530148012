import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export const isHaveNameAndSurname: ValidatorFn = (fioControl: AbstractControl): ValidationErrors | null => {
  if (!fioControl.value) {
    return null;
  }

  const fioArray = fioControl.value.split(' ');
  const surnameNotEmpty = fioArray[0] !== '';
  const nameNotEmpty = fioArray[1] && fioArray[1] !== '';

  return surnameNotEmpty && nameNotEmpty
    ? null
    : {
      errorMessage: 'form.error.name.and.surname.required',
    };
};

@Directive({
  selector: '[taxIsHaveNameAndSurnameValidator]',
  providers: [{
    provide: NG_VALIDATORS, useExisting: isHaveNameAndSurnameDirective, multi: true,
  }],
})
export class isHaveNameAndSurnameDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return isHaveNameAndSurname(control);
  }
}
