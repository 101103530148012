import { Component } from '@angular/core';

@Component({
  selector: 'tax-modal-content',
  templateUrl: 'modal-content.component.html',
  styleUrls: ['modal-content.component.styl'],
})

export class ModalContentComponent {
}
