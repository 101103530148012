import { Injectable } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { AppModule } from '../../app.module';

export enum SkipPreloader {
  true = 'skipPreloader',
  false = '',
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private static loaderEnabled: boolean;
  loaders: number[] = [];

  get loaderEnabled(): boolean {
    return LoaderService.loaderEnabled;
  }

  public showLoader(loaderId: number): void {
    this.loaders.push(loaderId);
    LoaderService.loaderEnabled = true;
  }

  public hideLoader(loaderId: number): void {
    const loaderIndex = this.loaders.findIndex(id => id === loaderId);
    if (loaderIndex !== -1) {
      this.loaders.splice(loaderIndex, 1);
    }

    if (this.loaders.length === 0) {
      LoaderService.loaderEnabled = false;
    }
  }
}

export function LoaderEnabled(): MethodDecorator {
  return (target: unknown, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      const loaderService = AppModule.injector.get<LoaderService>(LoaderService);
      const loaderId = Math.random();

      const skipPreloader = Object.values(args)
        .find(propertyName => propertyName === SkipPreloader.true);
      if (skipPreloader) {
        return original.apply(this, arguments);
      }

      loaderService.showLoader(loaderId);
      return original.apply(this, arguments)
        .pipe(
          map((res) => {
            loaderService.hideLoader(loaderId);
            return res;
          }),
          finalize(() => {
            loaderService.hideLoader(loaderId);
          }),
        );
    };
    return descriptor;
  };
}
