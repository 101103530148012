<div class="ui-kit-rows-layout" data-qa-block="selected-options">
  <div class="ui-kit-block-title" data-qa="block-title" *ngIf="title">{{ title | translate }}</div>

  <div
    *ngIf="!items?.length && placeholder; else itemsTemplate"
    class="ui-kit-grey-text"
    data-qa="empty-list-text"
  >
    {{ placeholder | translate }}
  </div>

  <ng-template #itemsTemplate>
    <div class="ui-kit-ellipsis-text" data-qa="options-list">
      <!--TODO: fix $any-->
      <span
        *ngFor="let item of items; trackBy: utilService.trackById"
        class="ui-kit-dot-separated-item"
        data-qa="options-item"
      >
        <ng-container *ngIf="!itemTemplate">
          {{
            isNeedTranslate
              ? ($any(item)[iterableProperty] || item | translate)
              : $any(item)[iterableProperty] || item
          }}
        </ng-container>
        <ng-container *ngIf="itemTemplate">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
          ></ng-container>
        </ng-container>
      </span>
    </div>
  </ng-template>

  <button
    data-qa-button="select-options"
    class="ui-kit-button_link"
    (click)="openModal.emit()"
    translate
    type="button"
  >
    group.account.select
  </button>
</div>
