import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[taxNumbersOnly]',
})
export class NumbersOnlyDirective {
  // @ts-ignore
  @HostListener('paste', ['$event']) onCtrlV(event): void {
    const pastedValue: string = (event.clipboardData || (window as any).clipboardData).getData('text');
    const isNumber: boolean = pastedValue === pastedValue.replace(/\D/g, '');

    if (!isNumber) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    if (
      // Allow numbers
      ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)
      // Allow keys
      || ['Backspace', 'Backspace', 'Delete', 'Enter', 'Escape'].includes(event.key)
      // Allow: Ctrl+A
      || ((event.key === 'a' || event.key === 'A') && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+C
      || ((event.key === 'c' || event.key === 'C') && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+V
      || ((event.key === 'v' || event.key === 'V') && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+X
      || ((event.key === 'x' || event.key === 'X') && (event.ctrlKey || event.metaKey))
      // Allow: home, end, left, right
      || (event.key === 'Home' || event.key === 'End' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
      // let it happen, don't do anything
      return;
    }

    event.preventDefault();
  }
}
