import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '../modal.model';

@Component({
  selector: 'tax-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl'],
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('container', {
    read: ViewContainerRef,
    // @ts-ignore
  }) container: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
  ) {
  }

  ngAfterViewInit(): void {
    this.createComponent();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private createComponent(): void {
    const componentFactory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.modalData.component);
    const componentRef: ComponentRef<any> = this.container.createComponent(componentFactory);
    Object.assign(componentRef.instance, this.modalData.componentInputs);
    componentRef.changeDetectorRef.detectChanges();
  }
}
