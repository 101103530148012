import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailConfirmationComponent } from './email-confirm.component';
import { EmailConfirmService } from '../../../core/services/email-confirm.service';
import { ModalService } from '../../../core/modal/modal.service';

@Directive({
  selector: '[taxOpenEmailConfirmModal]',
})
export class OpenEmailConfirmModalDirective implements OnDestroy {
  @Output() emailCorrect: EventEmitter<string> = new EventEmitter<string>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private emailConfirmService: EmailConfirmService,
  ) {
  }

  @HostListener('click')
  openModal(): void {
    this.modalService
      .openOld(EmailConfirmationComponent, {})
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((email) => {
        // TODO: why we need this api call? Backend may do this without frontend call?
        this.emailConfirmService.getConfirm()
          .pipe(takeUntil(this.destroy$))
          .subscribe();
        this.emailCorrect.emit(email);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
