import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class EmailConfirmService {
  constructor(private httpClient: HttpClient) {}

  public sendEmail(mail: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/sendEmailWithAuth', {email: mail});
  }

  public validateSecretKey(secretKey: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/checkIsConfirm', {confirmIdf: secretKey});
  }

  public getConfirm(): Observable<void> {
    return this.httpClient.get<void>('/api/user/confirmEmail');
  }
}
