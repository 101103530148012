<mat-form-field [style]="styles">
  <input
    matInput
    [max]="maxDate || null"
    [min]="minDate || null"
    [attr.tabindex]="tabindex || null"
    [matDatepicker]="picker"
    [(ngModel)]="date"
    [taxAutofocus]="autofocus || false"
    (dateInput)="inputEvent.emit($event)"
    [placeholder]="label || '' | translate"
  />
  <mat-datepicker-toggle
    *ngIf="tooltip"
    matSuffix
    [for]="picker"
    nz-tooltip
    [nzTooltipTitle]="tooltipTemplate"
    [nzTooltipVisible]="isTooltipVisible"
  ></mat-datepicker-toggle>
  <mat-datepicker-toggle *ngIf="!tooltip" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-label *ngIf="label" translate data-qa="label">{{ label }}</mat-label>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<ng-template #tooltipTemplate>
  <ng-container *ngTemplateOutlet="tooltip"></ng-container>
</ng-template>
