<div class="tax-container">
  <tax-header [isKnownUser]="true"></tax-header>
  <div class="tax-wrapper">
    <tax-sidebar></tax-sidebar>
    <div class="tax-main">
      <tax-loader></tax-loader>
      <main class="page-content">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>
