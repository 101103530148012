import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tab } from './tabs.model';

@Component({
  selector: 'tax-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TabsComponent {
  @Input() tabs: Tab[] = [];
}
