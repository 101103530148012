<!--TODO: fix $any-->
<div
  class="loader-wrapper"
  [ngStyle]="$any(isMainContent && { 'margin-left': '160px' })"
  *ngIf="loaderEnabled"
>
  <div class="c_container">
    <div class="c c1"></div>
    <div class="c c2"></div>
    <div class="c c3"></div>
    <span> {{ 'common.loader.downloading' | translate }}...</span>
  </div>
</div>
