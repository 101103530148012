<tax-modal-header [closeFn]="closeModal">{{ title }}</tax-modal-header>

<tax-modal-content *ngIf="phone">
  <form (ngSubmit)="isBusinessProcess ? sendPasswordToUserViaBp() : sendPasswordToUserPhone()">
    <tax-phone-input
      class="phone-wrapper"
      data-qa-input="phone"
      placeholder="login.sms.phone.placeholder"
      [id]="'phone'"
      [required]="true"
      [formControl]="phone"
      [descriptionText]="'user.phone.change.modal.description'"
      inputStyles="max-width: 150px"
      [inputActionButton]="phoneActionButton"
      [disabled]="$any(phone.disabled || null)"
    ></tax-phone-input>
    <ng-template #phoneActionButton>
      <button
        translate
        data-qa-button="send-code"
        class="button_blue phone-input-button"
        [disabled]="phone.invalid || this.isCountdownActive || this.attemptCount === 0"
      >
        user.phone.change.button
      </button>
    </ng-template>
    <div *ngIf="sendError" data-qa="error-text" class="error-text bp-error" translate>
      {{ sendError }}
    </div>

    <div class="retries grey-text" *ngIf="$any(attemptCount) < MAX_ATTEMPTS_COUNT">
      <span *ngIf="attemptCount && countdown">
        {{ 'login.try.countdown' | translate }} {{ countdown }}
      </span>
      <br />
      {{ 'login.try' | translate }}: {{ attemptCount }}
    </div>
  </form>

  <div [class.error]="verifyError" class="password-wrapper" data-qa-input="sms-code">
    <label class="required-marker" translate data-qa="label-text"
      >user.phone.change.modal.pin</label
    >
    <input
      data-qa="input"
      placeholder="_ _ _ _ _"
      type="text"
      id="password"
      name="password"
      size="5"
      minlength="5"
      maxlength="5"
      [formControl]="passwordInput"
      (keydown.enter)="isBusinessProcess ? saveUserPhoneViaBp() : saveUserPhone()"
      [taxAutofocus]="passwordInput.enabled"
    />
    <span *ngIf="smsCodeParamsAttempts !== undefined" data-qa="sms-code-attempts" class="grey-text">
      {{ 'login.try' | translate }}: {{ smsCodeParamsAttempts }}
    </span>
    <span [hidden]="!verifyError" data-qa="error-text" class="error-text">
      {{ verifyError | translate }}
    </span>
  </div>
</tax-modal-content>

<tax-modal-footer>
  <button
    class="button_blue ui-kit-align-right"
    data-qa-button="save-phone"
    (click)="isBusinessProcess ? saveUserPhoneViaBp() : saveUserPhone()"
    translate
    [disabled]="!passwordInput.value || passwordInput.invalid"
  >
    {{ buttonText }}
  </button>
</tax-modal-footer>
