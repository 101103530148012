import { Component, Input } from '@angular/core';

@Component({
  selector: 'tax-switch',
  templateUrl: 'switch.component.html',
  styleUrls: ['switch.component.styl'],
})

export class SwitchComponent {
  @Input() active: boolean | undefined = false;
  @Input() disabled = false;
}
