import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { ValidationService } from '../../shared/components/form-controls/validation.service';
import { LoginService } from '../../core/services/login.service';

@Component({
  selector: 'tax-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.styl'],
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  password = new UntypedFormControl('', [Validators.required, this.validationService.isPasswordStrength.bind(this)]);
  confirmation = new UntypedFormControl('', [Validators.required, this.validationService.isEqual.bind(this, this.password)]);

  private routeParams: string = '';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private validationService: ValidationService,
  ) {
  }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.paramMap.get('params') || '';
    this.cookieService.delete('PLAY_SESSION');
    this.runIsEqualValidatorOnPasswordChange();
  }

  submit(): void {
    this.loginService.sendNewPassword(this.password.value, this.routeParams)
      .pipe(
        mergeMap(userData => this.loginService.singIn({
          login: userData.login,
          secret: this.password.value,
          rememberMe: false,
        })),
        takeUntil(this.destroy$),
      )
      .subscribe(
        () => this.router.navigate(['main/kkms']),
      );
  }

  private runIsEqualValidatorOnPasswordChange(): void {
    this.password.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.confirmation.updateValueAndValidity());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
