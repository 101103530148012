<button
  class="header_item menu_header_item"
  [matMenuTriggerFor]="langMenu"
  #lang="matMenuTrigger"
  [class.opened]="lang.menuOpen"
>
  {{ currentLanguage }}
</button>

<mat-menu #langMenu="matMenu" yPosition="above">
  <div
    mat-menu-item
    class="menu_header_item"
    (click)="setLanguage('kz')"
    *ngIf="currentLanguage === 'ru'"
  >
    KZ
  </div>
  <div
    class="menu_header_item"
    (click)="setLanguage('ru')"
    mat-menu-item
    *ngIf="currentLanguage === 'kz'"
  >
    RU
  </div>
</mat-menu>
