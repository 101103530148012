<tax-modal-header>{{ title }}</tax-modal-header>

<tax-modal-content>{{ dialogText | translate }}</tax-modal-content>

<tax-modal-footer>
  <button data-qa-button="close-modal" class="button_base" (click)="close()" translate>
    button.cancel
  </button>
  <button data-qa-button="apply-button" class="button_blue" (click)="apply()">
    {{ buttonText | translate }}
  </button>
</tax-modal-footer>
