import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[taxEmailValidator]',
  providers: [{
    provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true,
  }],
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const reg: RegExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z0-9_\-\.])+$/;
    const isValid: boolean = reg.test(control.value);
    return isValid ? null : {
      invalidEmail: {
        valid: false,
      },
    };
  }
}
