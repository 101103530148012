<tax-modal-header>group.modal.personal.account</tax-modal-header>

<tax-modal-content>
  <div class="modal_account_settings">
    <div class="setting_item ui-kit-centering-string" data-qa-input="select-all">
      <input
        data-qa="input"
        id="selectall"
        type="checkbox"
        [(ngModel)]="isSelectAllChecked"
        (change)="selectAll()"
      />
      <label for="selectall" data-qa="label-text" style="padding-left: 8px" translate
        >group.modal.retail.place.selectall</label
      >
    </div>
  </div>

  <div
    *ngFor="let group of groupsOfPermissions; trackBy: groupsTrackBy"
    [attr.data-qa-block]="group.name"
  >
    <div *ngIf="group.modules.length">
      <hr class="greyline_block" />
      <div class="modal_account_settings">
        <div class="ui-kit-block-title" data-qa="block-title">{{ group.name | translate }}</div>
        <div class="setting_items" data-qa="permissions-list">
          <div
            *ngFor="let module of group.modules; trackBy: permissionsTrackBy"
            class="setting_item ui-kit-centering-string"
            (click)="selectModule(module)"
            [attr.data-qa-input]="module.description"
          >
            <input type="checkbox" [checked]="module.selected" data-qa="input" />
            <span class="description" data-qa="label-text">{{
              module.description | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</tax-modal-content>

<tax-modal-footer>
  <button
    data-qa-button="save-permissions"
    (click)="saveChanges()"
    class="button_blue ui-kit-align-right"
    translate
  >
    modal.select
  </button>
</tax-modal-footer>
