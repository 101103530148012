import { appAIcon } from '@app/svg/a';
import { appAppStoreMonochromeIcon } from '@app/svg/app-store-monochrome';
import { appArrowBackIcon } from '@app/svg/arrow-back';
import { appBlockIcon } from '@app/svg/block';
import { appCancelIcon } from '@app/svg/cancel';
import { appCashboxIcon } from '@app/svg/cashbox';
import { appCertificateIcon } from '@app/svg/certificate';
import { appCertificateDoneIcon } from '@app/svg/certificate-done';
import { appCoinsIcon } from '@app/svg/coins';
import { appCtoAltIcon } from '@app/svg/cto-alt';
import { appCtoIcon } from '@app/svg/cto';
import { appDeleteIcon } from '@app/svg/delete';
import { appDocsIcon } from '@app/svg/docs';
import { appDocumentDoneIcon } from '@app/svg/document-done';
import { appDoneIcon } from '@app/svg/done';
import { appDownloadIcon } from '@app/svg/download';
import { appEditIcon } from '@app/svg/edit';
import { appErrorIcon } from '@app/svg/error';
import { appExitIcon } from '@app/svg/exit';
import { appExpandLessIcon } from '@app/svg/expand-less';
import { appExpandMoreIcon } from '@app/svg/expand-more';
import { appGooglePlayMonochromeIcon } from '@app/svg/google-play-monochrome';
import { appGroupIcon } from '@app/svg/group';
import { appHelpIcon } from '@app/svg/help';
import { appInfoIcon } from '@app/svg/info';
import { appKktIcon } from '@app/svg/kkt';
import { appLockIcon } from '@app/svg/lock';
import { appMoreIcon } from '@app/svg/more';
import { appNotificationsNoneIcon } from '@app/svg/notifications-none';
import { appOrganizationIcon } from '@app/svg/organization';
import { appPauseIcon } from '@app/svg/pause';
import { appPhoneIcon } from '@app/svg/phone';
import { appPointsaleIcon } from '@app/svg/pointsale';
import { appRefreshIcon } from '@app/svg/refresh';
import { appRepeatIcon } from '@app/svg/repeat';
import { appSimCardIcon } from '@app/svg/sim-card';
import { appStopIcon } from '@app/svg/stop';
import { appTengeIcon } from '@app/svg/tenge';
import { appTimeIcon } from '@app/svg/time';
import { appTransitLeftIcon } from '@app/svg/transit-left';
import { appTriangleDownIcon } from '@app/svg/triangle-down';
import { appTriangleUpIcon } from '@app/svg/triangle-up';
import { appUsersIcon } from '@app/svg/users';
import { appXIcon } from '@app/svg/x';
import { appZIcon } from '@app/svg/z';
import { SVG_CONFIG, SvgIconType } from '@ngneat/svg-icon/lib/providers';
import { appCancelCircleIcon } from '@app/svg/cancel-circle';
import { appHelpOutlineIcon } from '@app/svg/help-outline';
import { appLongArrowIcon } from '@app/svg/long-arrow';
import { appDocGreyIcon } from '@app/svg/doc-grey';
import { appSortIcon } from '@app/svg/sort';
import { appMonitoringIcon } from '@app/svg/monitoring';

export const projectIcons: SvgIconType[] = [
  appAIcon,
  appArrowBackIcon,
  appBlockIcon,
  appCancelIcon,
  appCancelCircleIcon,
  appCoinsIcon,
  appCtoAltIcon,
  appCtoIcon,
  appDeleteIcon,
  appDocsIcon,
  appDocGreyIcon,
  appDoneIcon,
  appDownloadIcon,
  appEditIcon,
  appErrorIcon,
  appExitIcon,
  appExpandLessIcon,
  appExpandMoreIcon,
  appGroupIcon,
  appHelpIcon,
  appHelpOutlineIcon,
  appInfoIcon,
  appKktIcon,
  appLongArrowIcon,
  appMonitoringIcon,
  appMoreIcon,
  appNotificationsNoneIcon,
  appOrganizationIcon,
  appPauseIcon,
  appPointsaleIcon,
  appRefreshIcon,
  appRepeatIcon,
  appSimCardIcon,
  appSortIcon,
  appStopIcon,
  appTengeIcon,
  appTimeIcon,
  appTransitLeftIcon,
  appTriangleDownIcon,
  appTriangleUpIcon,
  appUsersIcon,
  appXIcon,
  appZIcon,
];

export const projectBigIcons: SvgIconType[] = [
  appAppStoreMonochromeIcon,
  appGooglePlayMonochromeIcon,
  appCashboxIcon,
  appCertificateIcon,
  appCertificateDoneIcon,
  appDocumentDoneIcon,
  appLockIcon,
  appPhoneIcon,
];

export const svgIconsSettings: SVG_CONFIG = {
  sizes: {
    md: '24px',
  },
  defaultSize: 'md',
  icons: [...projectIcons, ...projectBigIcons],
};
