import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SvgIcons } from '@ngneat/svg-icon';
import { Pages } from 'src/app/system/pages';
import { UsersService } from '../../../system/users/user.service';

interface MenuItem {
  name: string;
  link: string;
  icon: SvgIcons;
  permission?: string;
  label?: string;
}

@Component({
  selector: 'tax-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.styl'],
})
export class SidebarComponent {
  menuItems: Observable<MenuItem[]> = this.usersService.getCurrentUserInfo()
    .pipe(
      map((userInfo) => {
        const menuItems: MenuItem[] = [{
          name: 'menu.kkms',
          link: Pages.Kkms,
          icon: 'kkt',
          permission: 'viewKkms',
        }, {
          name: 'menu.analytics',
          link: Pages.Analytics,
          icon: 'monitoring',
          label: 'NEW',
        }, {
          name: 'menu.payment.account',
          link: Pages.BillingAccount,
          icon: 'coins',
          permission: 'editOrgData',
        }, {
          name: 'menu.groups',
          link: Pages.Groups,
          icon: 'group',
          permission: 'viewUsers',
        }, {
          name: 'menu.users',
          link: Pages.Users,
          icon: 'users',
          permission: 'viewUsers',
        }, {
          name: 'menu.documents',
          link: Pages.Documents,
          icon: 'docs',
          permission: 'editContract',
        }, {
          name: 'menu.organizations',
          link: Pages.Organization,
          icon: 'organization',
        }, {
          name: 'menu.tariffs',
          link: Pages.Tariffs,
          icon: 'tenge',
          permission: 'billing.integration',
        }, {
          name: 'menu.cto',
          link: Pages.Cto,
          icon: 'cto-alt',
          permission: 'cto.integration',
        }];
        return menuItems.filter(menuItem => !menuItem.permission || userInfo.uiPermissions[menuItem.permission]);
      }),
    );

  constructor(
    private usersService: UsersService,
  ) {
  }
}
