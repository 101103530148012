import { ElementRef, Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[taxPhoneMask]',
})
export class PhoneMaskDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('input')
  onChange() {
    this.el.nativeElement.value = this.onInputChange(this.el.nativeElement.value, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  // @ts-ignore
  keydownBackspace(event) {
    const newValue = this.onInputChange(event.target.value, true);
    this.el.nativeElement.value = newValue;

    // Асинхронно удаляем символ "+", т.к. если сразу послать пустое значение, то оно не измениться на UI
    if (newValue.length === 2 && newValue.startsWith('+')) {
      setTimeout(() => {
        this.el.nativeElement.value = '';
      });
    }
  }

  onInputChange(event: string, backspace: boolean): string {
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal += '+';
    } else if (newVal.length <= 1) {
      newVal = newVal.replace(/^(\d{0,1})/, '+$1');
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, '+$1 $2');
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '+$1 $2 $3');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})/, '+$1 $2 $3-$4');
    } else if (newVal.length <= 11) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '+$1 $2 $3-$4-$5');
    } else {
      newVal = newVal.substring(0, 11);
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '+$1 $2 $3-$4-$5');
    }
    if (backspace && newVal.length === 1) {
      newVal = '';
    }
    return newVal;
  }
}
