import { Directive, ElementRef, EventEmitter, inject, Output } from '@angular/core';

@Directive({
  selector: '[taxElementShow]',
})
export class ElementShowDirective {
  private changes?: MutationObserver;

  @Output() public taxElementShow = new EventEmitter();

  constructor() {
    this.observeElementVisibility();
  }

  private observeElementVisibility(): void {
    const hostElement = inject(ElementRef).nativeElement;

    this.changes = new MutationObserver(() => {
      this.taxElementShow.emit();
      this.changes?.disconnect();
    });

    this.changes.observe(hostElement, {
      attributes: true,
      childList: true,
      characterData: false,
    });
  }
}
