import { Component, Input } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'tax-modal-header',
  templateUrl: 'modal-header.component.html',
  styleUrls: ['modal-header.component.styl'],
})

export class ModalHeaderComponent {
  @Input() closable: boolean = true;
  @Input() closeFn?: () => void;
  @Input() onCloseEmittedData: any = null;

  constructor(
    private modalService: ModalService,
  ) {
  }

  closeModal(): void {
    if (this.closeFn) {
      this.closeFn();
    } else {
      this.modalService.close(this.onCloseEmittedData);
    }
  }
}
