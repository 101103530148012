<div class="checkbox-wrapper">
  <input
    data-qa="checkbox"
    class="checkbox-input"
    type="checkbox"
    [id]="id"
    [formControl]="checkboxControl"
  />

  <label class="checkbox-label" [attr.for]="id">
    <span *ngIf="labelText" data-qa="checkbox-label-text">
      {{ labelText | translate }}
    </span>
    <span *ngIf="labelTemplate" data-qa="checkbox-label-text">
      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </span>
  </label>
</div>
