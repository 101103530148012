import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

export type SupportedLanguages = 'kz' | 'ru';
export type IsoCodeSupportedLanguage = 'kk' | 'ru';
export interface CurrentLanguage {
  language: SupportedLanguages;
  iso: IsoCodeSupportedLanguage;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languageChanges: ReplaySubject<CurrentLanguage> = new ReplaySubject<CurrentLanguage>(1);
  // @ts-ignore
  currentLanguage: CurrentLanguage;

  constructor(
    private dateAdapter: DateAdapter<any>,
    private cookieService: CookieService,
    private translate: TranslateService,
  ) {
    const lastUsedLanguage = (localStorage.getItem('lang') as SupportedLanguages);
    const browserLanguage = this.isoCodeToLangCode(navigator.language as IsoCodeSupportedLanguage);
    this.setAppLanguage(lastUsedLanguage || browserLanguage);
  }

  changeLanguage(language: SupportedLanguages): void {
    localStorage.setItem('lang', language);
    this.setAppLanguage(language);
  }

  private setAppLanguage(language: SupportedLanguages): void {
    this.translate.setDefaultLang(language);
    this.cookieService.set('PLAY_LANG', language);
    this.updateLanguageStream(language);
    this.dateAdapter.setLocale(this.currentLanguage?.iso);
  }

  private updateLanguageStream(newValue: SupportedLanguages): void {
    this.currentLanguage = {
      language: newValue,
      iso: this.getIsoLanguageCode(newValue),
    };
    this.languageChanges.next(this.currentLanguage);
  }

  private getIsoLanguageCode(language: SupportedLanguages): IsoCodeSupportedLanguage {
    return language === 'ru' ? 'ru' : 'kk';
  }

  private isoCodeToLangCode(isoCode: IsoCodeSupportedLanguage): SupportedLanguages {
    return isoCode === 'ru' ? 'ru' : 'kz';
  }
}
