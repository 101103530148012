import { AfterViewInit, Component, forwardRef, inject, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationService } from '../validation.service';
import { BaseInputControlComponent } from '../base-input-control/base-input-control.component';

@Component({
  selector: 'tax-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneInputComponent,
      multi: true,
    },
  ],
})
export class PhoneInputComponent extends BaseInputControlComponent implements AfterViewInit {
  @Input() allowKzPhoneOnly = false;
  mask: any = ['+', /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  private validationService: ValidationService = inject(ValidationService);

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.ngControl?.control?.addValidators(this.validationService.phoneValidator());
    if (this.allowKzPhoneOnly) {
      this.ngControl?.control?.addValidators(this.validationService.kzPhoneValidator());
    }
  }

}
