import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

interface Options {
  withSeconds?: boolean;
}

@Pipe({
  name: 'dateTime',
})

export class DateTimePipe implements PipeTransform {
  transform(value: Date | number | undefined, options: Options = {}): string {
    if (!value) {
      return '';
    }

    const format = options.withSeconds ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy HH:mm';
    return formatDate(value, format, 'ru-kz');
  }
}
