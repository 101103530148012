import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[taxScrollbar]',
  templateUrl: 'scrollbar.component.html',
  styleUrls: ['scrollbar.component.styl'],
  encapsulation: ViewEncapsulation.None,
})

export class ScrollbarComponent {
}
