import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from './loader.service';
import {
  BankData,
  IOrganisation,
  OrganisationForm,
  OrganisationType,
  PersonWorkPosition,
} from '../models/organisation.model';

@Injectable({
  providedIn: 'root',
})

export class OrganizationService {
  constructor(private httpClient: HttpClient) {
  }

  @LoaderEnabled()
  public getOrganization(): Observable<IOrganisation> {
    return this.httpClient.get<IOrganisation>('/api/organisation');
  }

  @LoaderEnabled()
  public getPositions(): Observable<PersonWorkPosition[]> {
    return this.httpClient.get<PersonWorkPosition[]>('/api/positions');
  }

  @LoaderEnabled()
  public getOrganisationTypes(): Observable<OrganisationForm[]> {
    return this.httpClient.get<OrganisationForm[]>('/api/organisation/organisationForms');
  }

  @LoaderEnabled()
  public getBanks(): Observable<BankData[]> {
    return this.httpClient.get<BankData[]>('/api/banks');
  }

  public getOrganisationType(inn: string): OrganisationType {
    return parseInt(inn.charAt(4), 10) > 3 ? OrganisationType.Legal : OrganisationType.Individual;
  }
}
