import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PreloaderDirective } from './preloader.directive';
import { PreloaderComponent } from './preloader.component';

@NgModule({
  imports: [
    TranslateModule,
  ],
  exports: [PreloaderDirective, PreloaderComponent],
  declarations: [PreloaderDirective, PreloaderComponent],
  providers: [],
})
export class PreloaderModule {
}
