import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'kzCurrency',
})

export class KzCurrencyPipe implements PipeTransform {
  transform(value: number | undefined): string | null {
    if (value === undefined) {
      return null;
    }

    const hasDecimal = value % 1 !== 0;
    const digitsInfo = hasDecimal ? '1.2-2' : '1.0-0';
    const currency = formatCurrency(value, 'ru-kz', getCurrencySymbol('KZT', 'narrow'), 'KZT', digitsInfo);

    return String(value).length === 4
      // don't add a thousand space separator if number have only 4 digits.
      ? currency.replace(/\s/, '')
      : currency;
  }
}
