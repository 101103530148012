import { Injectable } from '@angular/core';
import { YM_COUNTER_ID } from './metrics.module';

@Injectable()
export class MetricsService {
  constructor(
    private window: Window,
  ) {
  }

  ymGoal(goalName: string): void {
    this.window.ym(YM_COUNTER_ID, 'reachGoal', goalName);
  }
}
