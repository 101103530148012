import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  forwardRef,
  Input,
  Output, TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tax-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor {
  // w3c input attributes
  @Input() autofocus: boolean = false;

  // w3c global attributes
  @Input() id?: string;
  // @ts-ignore
  @Input() tabindex: string = null;

  // component's inputs
  @Input() label?: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() styles?: string;
  @Input() isTooltipVisible = false;
  /**
   * Используется observable, а не примитив, т.к. надо получать любое изменения значения, в том числе с "true" на "true"
   * Например, пользователю отобразили тултип. Далее он скрывается, пользователь меняет данные, но они тоже неверны. В таком случае надо снова отобразить tooltip. В случае с примитивом это не получится сделать
   * @param isVisible$
   */
  @Input() set isTooltipVisible$(isVisible$: BehaviorSubject<boolean>) {
    isVisible$
      .pipe(untilDestroyed(this))
      .subscribe((isVisible) => {
        /**
         * Если значение было 'true' и новое тоже 'true', то tooltip не отобразится
         * Для этого добавлен костыль с переключением значения isTooltipVisible
         */
        if (isVisible && this.isTooltipVisible === isVisible) {
          this.isTooltipVisible = false;
          this.changeDetectorRef.detectChanges();
        }
        this.isTooltipVisible = isVisible || false;
        this.changeDetectorRef.detectChanges();
      });
  }

  @Input() tooltip: TemplateRef<any> | null = null;

  @Output() inputEvent: EventEmitter<MatDatepickerInputEvent<any, any>> = new EventEmitter<MatDatepickerInputEvent<any, any>>();

  // @ts-ignore
  date: UntypedFormControl;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  onChange = () => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.date = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
