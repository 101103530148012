<div *ngIf="total" class="bottom-block" data-qa-block="paginator">
  <span class="text-muted" data-qa="showed-pagination-items">
    Показано {{ pageIndex }} из {{ total }}
  </span>

  <button
    data-qa-button="get-previous-page"
    (click)="previousPage()"
    [disabled]="pageIndex === 1"
    class="back-button"
  ></button>

  <button
    data-qa-button="get-next-page"
    (click)="nextPage()"
    [disabled]="pageIndex === total"
    class="next back-button"
  ></button>
</div>
