import { Pipe, PipeTransform } from '@angular/core';
import { isItemExist } from '../file.utils';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchProperties: (string | undefined)[] = ['title']): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();
    searchProperties.push('description');
    const propertiesToSearch = searchProperties
      .filter(isItemExist)
      .map(property => property?.toLowerCase());

    return items.filter(item => propertiesToSearch.some(property => item[property]?.toLowerCase().includes(searchText)));
  }
}
