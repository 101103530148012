import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from './loader.service';
import { IDetailsRequestBody } from '../../system/cto/cto-details/cto-details.component';
import { ICto } from '../models/cto.model';
import { showErrorAlert, showSuccessAlert } from '../../shared/components/alerts/alert.decorator';

@Injectable()
export class CtoService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  @LoaderEnabled()
  public getContracts(): Observable<any> {
    return this.httpClient.get('/api/cto/contracts');
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getContract(id: string): Observable<any> {
    return this.httpClient.get(`/api/cto/contracts/${id.toString()}`);
  }

  public getCtoList(modelTitle?: string): Observable<Array<ICto>> {
    let params = new HttpParams();
    if (modelTitle) {
      params = params.set('kkmModel', modelTitle);
    }
    return this.httpClient.get<Array<ICto>>('/api/cto/ctos', {
      params,
    });
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getKkmHistory(id: string): Observable<any> {
    return this.httpClient.get(`/api/cto/events/kkm/${id}`);
  }

  @showErrorAlert()
  @LoaderEnabled()
  // @ts-ignore
  public saveCto(cto): Observable<any> {
    return this.httpClient.post('/api/cto/contract', cto);
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getCtoDetails(details: IDetailsRequestBody): Observable<any> {
    return this.httpClient.post('api/cto/cto-contract-data', details);
  }

  // @ts-ignore
  public getKkms(criteria): Observable<any> {
    return this.httpClient.post('/api/cto/search-kkms', criteria);
  }

  @showErrorAlert()
  @LoaderEnabled()
  public addKkms(body: { kkmIds: Array<number>; contractId: number }): Observable<any> {
    return this.httpClient.post('/api/cto/contracts/add-cash-registers', body);
  }

  @showErrorAlert()
  public allowNewSim(body: { kkmId: string; ctoIinOrBin: string; simNumber: string }): Observable<any> {
    return this.httpClient.post('/api/cto/sim/accepted', body);
  }

  @showErrorAlert()
  public rejectNewSim(body: { kkmId: string; ctoIinOrBin: string; simNumber: string }): Observable<any> {
    return this.httpClient.post('/api/cto/sim/rejected', body);
  }

  @showSuccessAlert({
    title: 'Success!',
  })
  @showErrorAlert()
  public terminateContract(contractId: string): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(`/api/cto/contract/terminate/${contractId}`, {});
  }
}
