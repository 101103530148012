import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, pipe, ReplaySubject, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppModule } from '../../app.module';

export function throwNormalizedError<T>(): MonoTypeOperatorFunction<T> {
  return pipe(
    catchError((response:HttpErrorResponse) => getErrorCode(response)
      .pipe(
        switchMap(errorCode =>
          // Возвращаем полученную от сервера ошибку, обогащённую дополнительным свойством с кодом ошибки, которую мы и используем в UI
          throwError(() => new HttpErrorResponse({
            error: {
              originalError: response.error,
              ofdErrorCode: errorCode,
            },
            status: response.status,
            headers: response.headers,
            statusText: response.statusText,
            url: response.url || undefined,
          }))),
      )),
  );
}

/**
 * * !!!!!!!!!!!!
 * В интерсепторе ошибки, пришедшие с 200 кодом ответа преобразуются в 400 и возвращаются сюда как конкатенация "message" (можно определить через `context: errorPrefix('...')`) и "errorCode"
 * !!!!!!!!!!!!
 *
 * Форматы, в которых могут прийти ошибки с сервера
 *
 * 1. API 2xx, 4xx
 * Пример: /api/administration/update-user-phone
 * `{
 *     ...
 *     "errorCode": "INVALID_CODE",
 *     ...
 *     "message": "phone.change.sms.code.error"
 * }`
 *
 * 2. API 2xx
 * Пример: /api/user/sms/send-new-phone
 * `{
 *     "errorCode": "NOT_MOBILE_PHONE",
 *     ...
 *     ...
 * }`
 *
 * 3. Бизнес процесс 4xx
 * `{
 *     "requested": {
 *         "pid": "8275063",
 *         "tid": "8275109",
 *         "userId": 4923
 *     },
 *     "error": "sms.code.validation.error.INVALID_CODE"
 * }`
 *
 * 4. 5xx (падения сервера, ошибки типа "This exception has been logged with id 82k1do9hp" и т.д.)
 * `<html>
 * <head><title>504 Gateway Time-out</title></head>
 * <body>
 * <center><h1>504 Gateway Time-out</h1></center>
 * <hr><center>nginx</center>
 * </body>
 * </html>`
 *
 */
function getErrorCode(response: HttpErrorResponse): Observable<string> {
  const translateService = AppModule.injector.get<TranslateService>(TranslateService);
  let errorCode = '';

  const isBp400Error = Boolean(response.error?.requested);
  const isHttpResponse400Error = Boolean(response.error?.message);
  const isOldFormatError = Boolean(response.error);

  if (isBp400Error) {
    errorCode = response.error.error || response.error?.errorCode;
  } else if (isHttpResponse400Error) {
    if (response.error.message && response.error.errorCode) {
      errorCode = `${response.error.message}.${response.error.errorCode}`;
    } else
    if (response.error.message) errorCode = `${response.error.message}`;
    else if (response.error.errorCode) errorCode = `${response.error.errorCode}`;
  } else if (isOldFormatError) {
    errorCode = response.error;
  } else {
    errorCode = 'error.common.text';
  }

  return translateService.get(errorCode)
    .pipe(
      map((translate) => {
        const isTranslatableMessage = Boolean(translate) && translate !== errorCode;
        return isTranslatableMessage ? errorCode : 'error.common.text';
      }),
    );
}

@Injectable()
export class ErrorService {
  errorType$: ReplaySubject<string | void> = new ReplaySubject<string | void>(1);

  constructor() { }

  set errorType(type: string) {
    this.errorType$.next(type);
  }

  public clearError() {
    this.errorType$.next('');
  }
}
