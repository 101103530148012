import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'taxDate',
})

export class TaxDatePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return;
    }

    return formatDate(value, 'dd.MM.yyyy', 'ru-kz');
  }
}
