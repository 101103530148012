import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UsersService } from '../../system/users/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.usersService.getCurrentUserInfo()
      .pipe(
        first(),
        map((userInfo) => {
          if (!userInfo) {
            this.router.navigateByUrl('');
            return false;
          }
          return true;
        }),
      );
  }
}
