import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { defer, of, OperatorFunction, Subject } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../../../core/services/login.service';
import { ConfirmBySmsService } from './confirm-by-sms.service';
import { UsersService } from '../../../../system/users/user.service';
import { BpService } from '../../../../core/services/bp.service';
import {
  BpSmsCodeForm,
  BpVerifyBySmsRequest,
} from '../../../../system/auth/registration/registration-by-sms/registration-by-sms.model';
import { ModalService } from '../../../../core/modal/modal.service';
import { ModalBusinessProcessData } from './confirm-by-sms.model';
import { LanguageService } from '../../../../core/services/language.service';
import { AlertsService } from '../../../../core/services/alerts.service';
import { ValidationService } from '../../form-controls/validation.service';
import { throwNormalizedError } from '../../../../core/services/error.service';
import {
  EditEntrepreneurForm,
  EditOrganisationForm,
} from '../../../../system/organization/organisation.model';

@Component({
  selector: 'tax-confirm-by-sms',
  templateUrl: 'confirm-by-sms.component.html',
  styleUrls: ['confirm-by-sms.component.styl'],
  providers: [
    ConfirmBySmsService,
  ],
})
export class ConfirmBySmsComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() set businessProcessData(data: ModalBusinessProcessData) {
    this._businessProcessData = data;
    this.isBusinessProcess = true;
  }

  @Input() businessProcessMessage = 'changePhoneMessage';
  @Input() buttonText = '';
  @Input() phoneValidator?: ValidatorFn;
  @Input() isBusinessProcess = false;
  @Input() userId?: number;

  attemptCount?: number;
  smsCodeParamsAttempts?: number;
  MAX_ATTEMPTS_COUNT: number = this.confirmBySmsService.MAX_ATTEMPTS_COUNT;
  countdown?: string;
  isCountdownActive = false;

  phone?: UntypedFormControl;
  passwordInput: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  sendError? = '';
  verifyError = '';

  private _businessProcessData?: ModalBusinessProcessData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private modalService: ModalService,
    private loginService: LoginService,
    private confirmBySmsService: ConfirmBySmsService,
    private languageService: LanguageService,
    private usersService: UsersService,
    private bpService: BpService,
    private validationService: ValidationService,
  ) {}

  ngOnInit(): void {
    if (this.isBusinessProcess) {
      this.confirmBySmsService.attemptCount$
        .pipe(takeUntil(this.destroy$))
        .subscribe((attemptCount) => {
          this.setPasswordInputState(attemptCount);
        });
    }

    this.startSubBp();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  closeModal = (isSuccess: boolean = false): void => {
    defer(() => (this.isBusinessProcess && !isSuccess
      ? this.bpService.goToStepAndGetForm('cancelProcessMessage', '', {})
      : of(true)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.modalService.close<typeof ConfirmBySmsComponent>(isSuccess ? this.phone?.value : null);
      });
  };

  saveUserPhone(): void {
    if (!this.phone || !this.userId) {
      return;
    }
    this.verifyError = '';

    this.usersService.changeUserPhone(this.phone.value, this.passwordInput.value, this.userId)
      .pipe(throwNormalizedError(), takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          // TODO: KZDEV-8948
          if (response?.message || response?.errorCode) {
            this.verifyError = `${response.message}.${response.errorCode}`;
          } else {
            this.alertsService.addSuccessAlert({
              message: 'user.phone.update.success',
            });
            this.closeModal(true);
          }
        },
        error: (response: HttpErrorResponse) => {
          this.verifyError = response.error?.ofdErrorCode;
        },
      });
  }

  saveUserPhoneViaBp(): void {
    this.verifyError = '';
    const { pid, tid } = this.bpService.getProcessIds();
    this.bpService.submit(pid, tid, {
      smsCode: this.passwordInput.value,
    })
      .pipe(throwNormalizedError(), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.closeModal(true);
        },
        error: (response: HttpErrorResponse) => {
          this.verifyError = response.error?.ofdErrorCode;
          this.smsCodeParamsAttempts = response.error?.originalError.errorState.smsCodeParamsAttempts;
        },
      });
  }

  sendPasswordToUserPhone(): void {
    if (!this.userId) return;

    this.sendError = undefined;
    this.verifyError = '';
    this.confirmBySmsService.sendPasswordToNewPhone({
      userId: this.userId,
      newPhone: this.phone?.value,
    })
      .pipe(
        tap((response) => {
          this.setPasswordInputState(response.params?.resendCount);
        }),
        mergeMap(() => this.confirmBySmsService.countdown$),
        throwNormalizedError(),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: this.onPasswordSendHandler.bind(this),
        error: (response: HttpErrorResponse) => {
          this.sendError = response.error?.ofdErrorCode;
        },
      });
  }

  sendPasswordToUserViaBp(): void {
    this.sendError = undefined;
    this.verifyError = '';
    // @ts-ignore
    const bpVerifyBySmsFields: BpVerifyBySmsRequest = {
      phone: this.phone?.value,
      userLang: this.languageService.currentLanguage.language,
      ...this._businessProcessData,
    };

    defer(() => (this.attemptCount === this.MAX_ATTEMPTS_COUNT
      ? this.bpService.submitAndGetForm<BpSmsCodeForm | EditEntrepreneurForm | EditOrganisationForm>(bpVerifyBySmsFields)
      : this.bpService.sendMessageCurrentBp('resendCodeMessage').pipe(
        switchMap(() => this.bpService.getCurrentBpFormData<BpSmsCodeForm | EditEntrepreneurForm | EditOrganisationForm>()),
      ))).pipe(
      filter((form) => {
        if (form.formKey === 'edit-entrepreneur' || form.formKey === 'edit-organisation') {
          this.closeModal(true);
          return false;
        }
        return true;
      }) as OperatorFunction<BpSmsCodeForm | EditEntrepreneurForm | EditOrganisationForm, BpSmsCodeForm>,
      tap((bpForm) => {
        this.setPasswordInputState(Number(bpForm.fields.smsCodeParamsResend));
        this.smsCodeParamsAttempts = Number(bpForm.fields.smsCodeParamsAttempts);
      }),
      mergeMap(() => this.confirmBySmsService.countdown$),
      throwNormalizedError(),
      takeUntil(this.destroy$),
    )
      .subscribe({
        next: this.onPasswordSendHandler.bind(this),
        error: (response: HttpErrorResponse) => {
          this.sendError = response.error?.ofdErrorCode;
        },
      });
  }

  private setPasswordInputState(attemptCount?: number): void {
    this.attemptCount = attemptCount;
    this.attemptCount === this.MAX_ATTEMPTS_COUNT
      ? this.passwordInput.disable()
      : this.passwordInput.enable();
  }

  private listenPhoneChanges(): void {
    this.phone?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.sendError = undefined;
      });
  }

  private onPasswordSendHandler(countdown: string): void {
    this.countdown = countdown;
    this.isCountdownActive = countdown !== '0:00';
  }

  private startSubBp(): void {
    defer(() => (this.isBusinessProcess
      ? this.bpService.goToStepAndGetForm(
        this.businessProcessMessage,
        '',
        {},
      )
      : of(undefined)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((formData) => {
        const phoneControlState = formData?.fields.phone
          ? {
            value: formData.fields.phone, disabled: true,
          }
          : '';
        this.phone = new UntypedFormControl(phoneControlState, [this.phoneValidator || (() => null), this.validationService.kzPhoneValidator()]);
        this.listenPhoneChanges();
      });
  }
}
