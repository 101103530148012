import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModulesPermissionsModel } from './modules-permissions.model';

@Injectable({
  providedIn: 'root',
})

export class AdminService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public getModules(): Observable<ModulesPermissionsModel[]> {
    return this.httpClient.get<ModulesPermissionsModel[]>('/api/administration/modules');
  }
}
