import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[taxAutofocus]',
})
export class AutofocusDirective implements OnChanges {
  // @ts-ignore
  @Input() taxAutofocus: boolean | string;

  constructor(
    private host: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isUsedInDefaultControls: boolean = this.taxAutofocus === '';
    const isUsedInCustomControls: boolean = this.taxAutofocus === true;

    if ('taxAutofocus' in changes && (isUsedInDefaultControls || isUsedInCustomControls)) {
      setTimeout(() => {
        this.host.nativeElement.focus();
      });
    }
  }
}
