import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { KkmsService } from '../../services/kkms.service';
import { OrganizationService } from '../../services/organization.service';
import {AccountService, INotificationsData} from '../../services/account.service';
import { ITotalCount } from '../../models/kkm.model';
import { IBalance, IOrganisation } from '../../models/organisation.model';
import { UsersService } from '../../../system/users/user.service';
import { LoginService } from '../../services/login.service';
import { AuthorizedUserInfo } from '../../../system/users/users.model';

@Component({
  selector: 'tax-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @Input() isKnownUser: boolean;

  // @ts-ignore
  userInfo$: Observable<AuthorizedUserInfo>;
  // @ts-ignore
  totalCount: ITotalCount;
  // @ts-ignore
  organization: IOrganisation;
  // @ts-ignore
  balance: IBalance;
  private subscription: Subscription[] = [];
  notificationsData: INotificationsData = {
    notifications: [],
    totalCount: 0,
    unreadCount: 0,
  };

  constructor(
    private kkmService: KkmsService,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private loginService: LoginService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.isKnownUser) {
      this.userInfo$ = this.usersService.getCurrentUserInfo();
      this.kkmService.getStatusesCount().subscribe(response => this.totalCount = response);
      this.getBalance();
      this.getOrganization();
      this.getNotifications();
    }
  }

  logout(): void {
    this.subscription.push(this.loginService.logout().subscribe(() => {
      this.router.navigate(['landing']);
    }));
  }

  private getOrganization(): void {
    this.subscription.push(this.organizationService.getOrganization().subscribe(
      (response: IOrganisation) => this.organization = response,
    ));
  }

  private getNotifications() {
    this.subscription.push(
      this.accountService.getNotificationsData().subscribe(data => this.notificationsData = data)
    );
  }

  private getBalance(): void {
    this.subscription.push(this.accountService.getBalance().subscribe(
      (response: IBalance) => this.balance = response,
      () => {},
    ));
  }

  goNotificationsPage(notificationId: number) {
    this.router.navigate(['/notifications-page'],{ queryParams: { notificationId } },
    );
  }

  getFirstLetter(userName: string): string {
    return userName.split('')[0].toLocaleUpperCase();
  }

  ngOnDestroy(): void {
    this.subscription.forEach((item: Subscription) => item.unsubscribe());
  }
}
