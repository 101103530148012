import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfirmBySmsComponent } from '../components/modals/confirm-by-sms/confirm-by-sms.component';
import { ModalService } from '../../core/modal/modal.service';

interface ModalData {
  title?: string;
  buttonText?: string;
}

@Directive({
  selector: '[taxOpenSignBySmsModal]',
})
export class OpenSignBySmsModalDirective implements OnDestroy {
  @Input() taxOpenSignBySmsModal: ModalData | '' = {
    title: 'sign.by.sms.title',
    buttonText: 'button.sign',
  };

  @Output() phone: EventEmitter<string> = new EventEmitter<string>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modalService: ModalService,
  ) {
  }

  @HostListener('click')
  openModal(): void {
    this.modalService
      // @ts-ignore
      .openOld(ConfirmBySmsComponent, this.taxOpenSignBySmsModal)
      .pipe(takeUntil(this.destroy$))
      .subscribe((phone) => {
        // TODO: where show error message
        // TODO: redirect to page or something like this + success message
        // or just do like setSignature?
        this.phone.emit(phone);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
