import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from './loader.service';
import { SmsCodeSendResponse } from '../../shared/components/modals/confirm-by-sms/confirm-by-sms.model';
import { Http200ErrorResponse } from '../models/http.model';
import { showErrorAlert, showSuccessAlert } from '../../shared/components/alerts/alert.decorator';

export interface SignInPayload { // логин пользователя
  login: string; // Пароль пользователя
  secret: string; // Запоминать ли пользователя
  rememberMe: boolean;
}

// Данные для логина пользователя по смс коду
export interface UserSmsLogin { // логин пользователя
  login: string; // СМС код
  code: string; // Запоминать ли пользователя
  rememberme: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class LoginService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private httpClient: HttpClient,
  ) {}

  @showErrorAlert()
  @LoaderEnabled()
  public singIn(user: SignInPayload): Observable<void | Http200ErrorResponse> {
    return this.httpClient.post<void | Http200ErrorResponse>('/api/user/login', user, this.headers);
  }

  @showErrorAlert()
  loginBySms(loginBySmsPayload: UserSmsLogin): Observable<SmsCodeSendResponse | Http200ErrorResponse> {
    return this.httpClient.post<SmsCodeSendResponse | Http200ErrorResponse>('/api/user/loginBySms', loginBySmsPayload);
  }

  public logout(): Observable<any> {
    return this.httpClient.post('/api/user/logout', {}, this.headers);
  }

  public getAnonymousUser(): Observable<any> {
    return this.httpClient.post('/api/user/login-anonymous', {}, this.headers);
  }

  @showSuccessAlert({
    message: 'user.password.restore.succeeded',
    title: 'user.password.restore.succeeded.title',
  })
  @showErrorAlert()
  @LoaderEnabled()
  public sendLogin(login: string): Observable<any> {
    return this.httpClient.post('/api/user/restore-password', {
      login,
    });
  }

  @showErrorAlert({
    message: 'user.password.failed',
  })
  @LoaderEnabled()
  public sendNewPassword(password: string | null, params: string): Observable<any> {
    return this.httpClient.post(`/api/user/new-password/${params}`, {
      password,
    });
  }
}
