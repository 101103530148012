import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { LanguageService } from '../../core/services/language.service';

@Pipe({
  name: 'dateAsync',
  pure: false,
})
export class DateAsyncPipe implements PipeTransform {
  asyncPipe: AsyncPipe = new AsyncPipe(this.cdr);

  constructor(
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  transform(value: string | undefined, ...args: any[]): any {
    const asyncOperation = this.transformAsync(value, ...args);
    return this.asyncPipe.transform(asyncOperation);
  }

  transformAsync(value: string | undefined, args?: any): Observable<string> {
    return this.languageService.languageChanges
      .pipe(
        map((language) => {
          moment.locale(language.iso);

          return moment(value).format(args);
        }),
      );
  }
}
