<tax-modal-header> certificate.nca.modal.title </tax-modal-header>

<tax-modal-content>
  <div class="text-block">
    <svg-icon key="error"></svg-icon>
    <span *ngIf="message" translate>{{ message }}</span>
    <div *ngIf="!message">
      <p translate>certificate.warning.description</p>
      <br />
      <a href="http://pki.gov.kz/index.php/ru/ncalayer" target="_blank" class="link" translate
        >certificate.warning.help</a
      >
    </div>
  </div>
</tax-modal-content>

<tax-modal-footer>
  <button class="button_blue ui-kit-align-right" (click)="closeModal()" translate>
    certificate.warning.ok
  </button>
</tax-modal-footer>
