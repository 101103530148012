import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

export interface IAlert {
  title?: string;
  message: string;
  messageVariables?: Record<string, string | number>;
  replaceCondition?: RegExp; // Регулярное выражение по которому можем искать ошибку с сервера для замены на message
  type: AlertTypes;
}

type AlertTypes = 'danger' | 'success';

@Injectable({
  providedIn: 'root',
})

export class AlertsService {
  private alertSubject$: ReplaySubject<IAlert[]> = new ReplaySubject<IAlert[]>(1);
  private alerts: IAlert[] = [];
  private currentUrl = window.location.hash.split('#')[1];

  alerts$: Observable<IAlert[]> = this.alertSubject$.asObservable();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.clearAlertsStoreOnRouteChange();
  }

  add(partialAlert: Partial<IAlert>): void {
    const alert: IAlert = {
      title: partialAlert.title,
      message: partialAlert.message || '',
      messageVariables: partialAlert.messageVariables,
      type: partialAlert.type || 'danger',
    };
    this.alerts = [alert];
    this.alertSubject$.next(this.alerts);
  }

  addDefaultError(): void {
    const alert: IAlert = {
      message: 'alert.danger.common.text',
      title: 'alert.danger.common.title',
      type: 'danger',
    };

    this.alerts.push(alert);
  }

  addSuccessAlert(partialAlert: Partial<IAlert> = {}): void {
    const alert: IAlert = {
      title: partialAlert.title,
      message: partialAlert.message || '',
      messageVariables: partialAlert.messageVariables,
      type: 'success',
    };
    this.add(alert);
  }

  clearAlertsStoreOnRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => (event instanceof NavigationEnd)) as OperatorFunction<Event, NavigationEnd>,
      )
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects !== this.currentUrl) {
          this.alerts = [];
          this.alertSubject$.next(this.alerts);
        }

        this.currentUrl = event.urlAfterRedirects;
      });
  }

  closeAlert(alertIndex: number): void {
    this.alerts.splice(alertIndex, 1);
    this.alertSubject$.next(this.alerts);
  }
}
