import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tax-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.sass'],
})
export class PaginatorComponent implements OnInit {
  @Input() pageIndex = 1;
  @Input() total = 0;
  @Output() nextPageEvent = new EventEmitter<number>();
  @Output() previousPageEvent = new EventEmitter<number>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.page) {
      this.pageIndex = Number(this.route.snapshot.queryParams.page);
    }
  }

  nextPage(): void {
    this.pageIndex += 1;
    this.nextPageEvent.emit(this.pageIndex);
  }

  previousPage(): void {
    this.pageIndex -= 1;
    this.previousPageEvent.emit(this.pageIndex);
  }
}
