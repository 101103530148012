import { Base64String } from './services/signature-nca-common-utils.service';

export type XmlFile = string;

export function base64toBlob(dataURI: Base64String): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {
    type: 'text/xml',
  });
}

interface DocumentOptions {
  isBase64?: boolean;
}

export function openDocumentInTab(document: XmlFile | Base64String, documentOptions?: DocumentOptions): void {
  const documentAsBlob = documentOptions?.isBase64
    ? base64toBlob(`data:text/plain;base64,${document}`)
    : document;

  const blob = new Blob([documentAsBlob], {
    type: 'text/xml',
  });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}

export function isItemExist<T>(item: T): item is NonNullable<T> {
  return item !== null && item !== undefined;
}
