<tax-modal-header>
  <ng-container *ngIf="type === 'users'">group.users</ng-container>
  <ng-container *ngIf="type === 'retailPlaces'">group.retail.place</ng-container>
  <ng-container *ngIf="type === 'groups'">user.form.users.group</ng-container>
</tax-modal-header>

<tax-modal-content>
  <tax-input
    [(ngModel)]="searchText"
    [placeholder]="
      type === 'users' ? 'user.modal.placeholder' : 'group.modal.retail.place.placeholder'
    "
    [autofocus]="true"
    [clearable]="true"
    data-qa-input="search-input"
  ></tax-input>

  <div class="select-all ui-kit-centering-string" data-qa-input="select-all">
    <input
      id="selectAll"
      type="checkbox"
      [(ngModel)]="isFullAccess"
      (change)="toggleItemsSelectedState()"
      data-qa="input"
    />
    <label for="selectAll" data-qa="label-text" translate>group.modal.retail.place.selectall</label>
  </div>

  <div class="modal_body_wrapper">
    <div class="modal_body" taxScrollbar data-qa-block="list-items">
      <div
        class="modal_item"
        *ngFor="
          let item of _items
            | filter: searchText:[propertiesMap?.title, type === 'users' ? 'login' : undefined];
          trackBy: trackById;
          let i = index
        "
        (click)="selectItem(item)"
        data-qa-input="item"
      >
        <input type="checkbox" data-qa="input" value="retailPlace" [checked]="item.selected" />
        <div class="modal_item_name" data-qa="label-text">
          {{ item[propertiesMap?.title || 'title'] }}
        </div>
        <div class="modal_item_description" data-qa="label-description-text">
          {{ item[propertiesMap?.description || 'description'] }}
        </div>
      </div>
    </div>
  </div>
</tax-modal-content>

<tax-modal-footer>
  <button
    data-qa-button="save"
    (click)="saveChanges()"
    class="ui-kit-button_blue ui-kit-align-right"
    translate
  >
    modal.select
  </button>
</tax-modal-footer>
