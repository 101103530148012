import { Pipe, PipeTransform } from '@angular/core';

export interface Selected {
  selected?: boolean;
}

@Pipe({
  name: 'selected',
  pure: false,
})

export class SelectedPipe implements PipeTransform {
  transform<T>(items: (T & Selected)[]): (T & Selected)[] {
    return items?.filter(item => item.selected);
  }
}
