import { NgModule } from '@angular/core';
import { provideSvgIconsConfig, SvgIconComponent } from '@ngneat/svg-icon';
import { CommonModule } from '@angular/common';
import { svgIconsSettings } from './svg-icons-settings';
import { IconComponent } from './icon.component';

@NgModule({
  imports: [
    SvgIconComponent,
    CommonModule,
  ],
  exports: [
    SvgIconComponent,
    IconComponent,
  ],
  declarations: [
    IconComponent,
  ],
  providers: [
    provideSvgIconsConfig(svgIconsSettings),
  ],
})
export class IconsModule {
}
