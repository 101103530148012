import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, share } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export type Settings = Record<string, string>;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private observable?: Observable<Settings>;
  private settings?: Settings;

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getSettings(): Observable<Settings> {
    if (this.settings) {
      return of(this.settings);
    }
    if (this.observable) {
      return this.observable;
    }
    this.observable = this.httpClient.get<Settings>('/api/taxpayer/settings/ui')
      .pipe(
        tap((settings) => {
          this.observable = undefined;
          this.settings = settings;
        }),
        share(),
      );
    return this.observable;
  }
}
