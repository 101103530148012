import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { CertificateErrorModalComponent } from '../components/modals/certificate-error/certificate-modal-error.component';
import { ModalService } from '../../core/modal/modal.service';
import {
  CertificateModalSignatureData,
  NcaLayerResponse,
  SignatureNcaCommonUtilsService,
} from '../services/signature-nca-common-utils.service';

interface CertificateModalData {
  data: CertificateModalSignatureData;
  title?: string;
}

@Directive({
  selector: '[taxOpenCertificateModal]',
})
export class OpenCertificateModalDirective {
  @Input() taxOpenCertificateModal: CertificateModalData = {
    data: {},
  };

  @Output() signatureGenerated: EventEmitter<CertificateModalSignatureData> = new EventEmitter<CertificateModalSignatureData>();

  constructor(
    private signatureService: SignatureNcaCommonUtilsService,
    private modalService: ModalService,
  ) {
  }

  @HostListener('click')
  openCertificateModal(): void {
    if (this.signatureService.isNcaEnabled()) {
      this.initSettings();
    } else {
      this.openErrorModal();
    }
  }

  private initSettings(): void {
    const data: CertificateModalSignatureData = _.cloneDeep(this.taxOpenCertificateModal.data || {});
    if (!data.doc || Object.keys(data.doc).length === 0) {
      data.doc = {
        signingDocument: data?.salt ? btoa(data.salt) : 'U0VDUkVUc2VjcmV0U0VDUkVUMDENCg==',
      };
      this.signatureService.setKeyPurpose('AUTHENTICATION');
    } else {
      this.signatureService.setKeyPurpose('SIGNATURE');
    }

    this.createSignature(data);
  }

  private createSignature(data: CertificateModalSignatureData): void {
    if (!data.doc) return;

    const countSignedDocuments = Object.keys(data?.signature || []).length;
    if (countSignedDocuments >= Object.keys(data.doc).length) {
      return;
    }

    const signingDocument = Object.values(data.doc)[countSignedDocuments];
    const signingDocumentKey = Object.keys(data.doc)[countSignedDocuments];

    this.signatureService.createCMSSignatureFromBase64(signingDocument, this.createCMSSignatureCallback.bind(null, signingDocumentKey, data));
  }

  private createCMSSignatureCallback = (documentKey: string, data: CertificateModalSignatureData, rw: NcaLayerResponse): void => {
    if (!rw.status || !data.doc || !rw.body?.result) return;

    const isUserStopSigning = rw.status && JSON.stringify(rw.body) === '{}';
    if (isUserStopSigning) return;

    data.signature = {
      ...data.signature,
      [documentKey]: rw.body?.result,
    };

    const isAllDocumentsSigned = Object.keys(data.doc).length === Object.keys(data.signature).length;
    if (isAllDocumentsSigned) {
      this.signatureGenerated.emit(data);
    } else {
      this.createSignature(data);
    }
  };

  private openErrorModal(): void {
    this.modalService.openOld(CertificateErrorModalComponent, {});
  }
}
