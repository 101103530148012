import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LanguageService } from '../../core/services/language.service';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private languageService: LanguageService,
  ) {
  }

  transform(value: any, args?: any): Observable<SafeHtml> {
    return this.languageService.languageChanges
      .pipe(
        map((language) => {
          moment.locale(language.iso);

          let fullDate = moment(value);
          if (args === 'TRASH') {
            fullDate = moment(value.substring(0, 8));
          }
          if (args === 'INVCDate' && value) {
            return moment(value.substring(0, 8)).format('D MMM YYYY');
          }
          return this.sanitizer.bypassSecurityTrustHtml(
            `<span style="display: flex;color: #333333;flex-direction: column;">${
              fullDate.get('date')} ${
              fullDate.format('MMM').substring(0, 3)} `
            + `<span style="color:#CCCCCC;">${fullDate.get('year')} г` + '</span>'
            + '</span>',
          );
        }),
      );
  }
}
