import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthorizedUserInfo, BlockUserBody, SaveUserBody, SessionUser, User } from './users.model';
import { LoaderEnabled } from '../../core/services/loader.service';
import { SmsCodeSendResponse } from '../../shared/components/modals/confirm-by-sms/confirm-by-sms.model';
import { AlertsService } from '../../core/services/alerts.service';
import { UtilsService } from '../../core/services/utils.service';
import { showErrorAlert } from '../../shared/components/alerts/alert.decorator';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private alertsService: AlertsService,
    private httpClient: HttpClient,
    private utilsService: UtilsService,
  ) {
  }

  getCurrentUserInfo(): Observable<AuthorizedUserInfo> {
    return this.httpClient.get<SessionUser>('/api/user/authuser')
      .pipe(
        map((userInfo: SessionUser) => {
          (userInfo as AuthorizedUserInfo).uiPermissions = userInfo.permissions.reduce((acc, item) => {
            // @ts-ignore
            acc[item.replace('default.', '')] = true;
            return acc;
          }, {});
          return userInfo;
        }) as OperatorFunction<SessionUser, AuthorizedUserInfo>,
        catchError((error: HttpErrorResponse) => {
          this.alertsService.add({
            message: error.error.error,
          });
          return throwError(error);
        }),
      );
  }

  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  public getUsers(searchCriteria: string = '', retailPlaceId: number = 0, groupId: number = 0): Observable<User[]> {
    const params = this.utilsService.getHttpParams({
      searchCriteria: searchCriteria ?? '',
      retailPlaceId,
      groupId,
    });
    return this.httpClient.get<User[]>('/api/administration/filter-users', {
      params,
    });
  }

  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  public getUser(id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/administration/users/${id}`)
      .pipe(
        map((user) => {
          if (user.phone) {
            user.phone = this.utilsService.normalizePhone(user.phone);
          }
          return user;
        }),
      );
  }

  // Данные в свагере не полные или не соответствуют правде
  @showErrorAlert()
  @LoaderEnabled()
  public editUser(user: SaveUserBody): Observable<void> {
    return this.httpClient.post<void>('/api/administration/save-user', user);
  }

  @LoaderEnabled()
  public blockUser(name: string): Observable<BlockUserBody> {
    return this.httpClient.post<BlockUserBody>((`/api/administration/block-user?login=${name}`), {
      login: name,
    });
  }

  changeUserPhone(newPhone: string, code: string, userId: number): Observable<SmsCodeSendResponse | void> {
    return this.httpClient.post<SmsCodeSendResponse | void>(('/api/administration/update-user-phone'), {
      newPhone, code, userId,
    });
  }

  sendSmsCodeToAuthorizedUser(): Observable<string> {
    return this.httpClient.post<string>(('/api/user/sms/send'), {});
  }
}
