<div
  class="page-header"
  data-qa-block="page-header-wrapper"
  *ngIf="
    showBackButton ||
    backButtonUrl ||
    titleText ||
    titleAdditionalInfo ||
    rightSide ||
    $any(visibleDropdownItems)?.length > 0
  "
>
  <tax-icon
    name="arrow-back"
    *ngIf="showBackButton || backButtonUrl"
    (click)="goBack()"
    class="go-back-button"
  ></tax-icon>

  <h2 class="page-header__title">
    <span class="title-text" *ngIf="titleText" data-qa="page-header">{{
      titleText | translate
    }}</span>
    <ng-container
      *ngIf="titleAdditionalInfo"
      [ngTemplateOutlet]="titleAdditionalInfo"
    ></ng-container>
  </h2>

  <div class="page-header__additional-content" *ngIf="rightSide">
    <ng-container [ngTemplateOutlet]="rightSide"></ng-container>
  </div>

  <!--TODO: fix $any-->
  <div
    class="page-header__dropdown"
    *ngIf="$any(visibleDropdownItems?.length) > 0"
    #dropdown
    data-qa-block="dropdown"
  >
    <tax-icon
      name="more"
      class="dropdown__icon"
      [class.active]="isDropdownVisible"
      (click)="isDropdownVisible = !isDropdownVisible"
    ></tax-icon>
    <div class="dropdown__container" [class.hidden]="!isDropdownVisible" data-qa="dropdown-list">
      <div
        *ngFor="let menuItem of visibleDropdownItems; trackBy: trackByTitle"
        class="dropdown__item"
        (click)="dropdownAction(menuItem)"
        data-qa="dropdown-item"
      >
        <tax-icon [name]="menuItem.icon"></tax-icon>
        <span data-qa="dropdown-item-text">{{ menuItem.title | translate }}</span>
      </div>
    </div>
  </div>
</div>

<ng-content></ng-content>

<tax-alerts></tax-alerts>
