import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { merge, Observable, Subject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { LoaderEnabled } from './loader.service';
import { IFeedbackForm } from '../../system/help/help.component';
import { IBalance, IInvoice, IInvoiceTariff, IReversRoll } from '../models/organisation.model';
import { IIsmetAccess } from '../../system/payment-account/payment-account.component';
import { showErrorAlert, showSuccessAlert } from '../../shared/components/alerts/alert.decorator';

interface IInvoiceTariffs {
  invoicesData: Array<IInvoiceTariff>;
}

export interface IInvoices {
  invoices: Array<IInvoice>;
}

interface IReversRolls {
  rolls: Array<IReversRoll>;
}

export interface INotification {
  id: number;
  dateTime: number;
  title: string;
  shortTitle: string;
  body: string;
  level: 'INFO' | 'WARNING' | 'DANGER';
  notificationType: 'BROADCAST_NOTIFICATION' | 'PRIVATE_NOTIFICATION';
  isRead: boolean;
}
export interface INotificationReadBody {
  id: number;
  notificationType: 'BROADCAST_NOTIFICATION' | 'PRIVATE_NOTIFICATION';
}

export interface INotificationFilters {
  fromDate: number;
  toDate: number;
  // pageSize: number;
  // page: number;
}

export interface INotificationsData {
  notifications: INotification[];
  totalCount: number;
  unreadCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  public getBalance(): Observable<IBalance> {
    return this.httpClient.get<IBalance>('/api/organisation/balance');
  }

  reloadNotificationsEvent$ = new Subject<void>();

  getNotificationsData(): Observable<INotificationsData> {
    return merge(this.reloadNotificationsEvent$.pipe(startWith(null)))
      .pipe(switchMap(() => this.httpClient.get<INotificationsData>('/api/notifications/bell')));
  }

  setNotificationsReadStatus(body: INotificationReadBody[]): Observable<any> {
    return this.httpClient.post<any>('/api/notifications/read', body);
  }

  getFilteredNotifications(body: INotificationFilters): Observable<INotificationsData> {
    return this.httpClient.post<INotificationsData>('/api/notifications/filtered', body);
  }

  public getInvoices(endDate: Date, startDate: Date): Observable<IInvoices> {
    const params = new HttpParams()
      .set('endDate', new Date(endDate).getTime().toString())
      .set('startDate', new Date(startDate).getTime().toString());
    return this.httpClient.get<IInvoices>('/api/organisation/invoices', {
      params,
    });
  }

  public getReverseRoll(endDate: Date, startDate: Date): Observable<IReversRolls> {
    const params = new HttpParams()
      .set('endDate', new Date(endDate).getTime().toString())
      .set('startDate', new Date(startDate).getTime().toString());
    return this.httpClient.get<IReversRolls>('/api/organisation/reverse-roll', {
      params,
    });
  }

  public getInvoice(id: number, date: string): Observable<IInvoiceTariffs> {
    const params = new HttpParams().set('invoiceDate', date);
    return this.httpClient.get<IInvoiceTariffs>(`api/organisation/invoices/${id}`, {
      params,
    });
  }

  @showSuccessAlert({
    message: 'help.notification.text.success',
    title: 'help.notification.title.success',
  })
  @showErrorAlert({
    message: 'help.notification.text.danger',
    title: 'help.notification.title.danger',
  })
  @LoaderEnabled()
  public sendFeedback(data: IFeedbackForm): Observable<null> {
    const formattedData = {
      subject: `${data.subject} from [${data.email}]`, text: data.text,
    };
    return this.httpClient.post<null>('/api/taxpayer/feedback', formattedData);
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getHelpText(): Observable<string> {
    return this.httpClient.get('/api/help', {
      responseType: 'text',
    });
  }

  @LoaderEnabled()
  public registerOrganisation(): Observable<null> {
    return this.httpClient.post<null>('api/ismet/registration', {});
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getIsmetToken(): Observable<IIsmetAccess> {
    return this.httpClient.get<IIsmetAccess>('/api/ismet/access');
  }
}
