export interface IOrganisation {
  actualAddress: string;
  billingAccountNumber?: string;
  fullTitle: string;
  id: number;
  inn: string;
  isEmailEnabled: boolean;
  isSmsEnabled: boolean;
  legalAddress: string;
  organisationForm: string;
  tenantId?: number;
  title: string;
  type: 0 | 1;
  bankData: BankData;
  okved?: string;
  responsiblePerson?: {
    email: string;
    name?: any;
    phone: string;
  };
  taxpayer: {
    middlename?: string;
    name?: string;
    surname?: string;
  };
}

export interface IBalance {
  amount: number;
  clientStatus: number;
}

export interface IReversRoll {
  amount: number;
  calcPeriod: number;
  docDate: string;
  invoiceId: number;
  typeOperationId: number;
}

export interface IInvoice {
  amount: number;
  amountTax: number;
  calcPeriod: string;
  invoiceDate: string; // format: 20180531000000
  invoiceId: number;
  invoiceNumb: number;
  invoicesData?: Array<IInvoiceTariff>;
  isBase: boolean;
}

export interface IInvoiceTariff {
  serviceAmount: number;
  serviceCount: number;
  tariffName: string;
}

export enum OrganisationType {
  Legal = '0',
  Individual = '1',
}

export interface OrganisationForm {
  id: number;
  name: string;
  shortTitle: string;
}

export interface BankData {
  bic: string;
  name: string;
  paymentAccount: string;
}

export interface PersonWorkPosition {
  id: number;
  name: string;
}
