import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderEnabled, SkipPreloader } from './loader.service';
import { IBp, IForm } from '../models/bp.model';
import { showErrorAlert } from '../../shared/components/alerts/alert.decorator';

export interface BusinessProcessIds {
  pid: string;
  tid: string;
}

interface StartBpOptions {
  skipLocationChange?: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class BpService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  @showErrorAlert()
  @LoaderEnabled() // skipLoader used for decorator
  public start(processName: string, data: any, options: StartBpOptions = {}, skipLoader = SkipPreloader.false): Observable<IBp> {
    return this.httpClient.post<IBp>(`/api/process/${processName}`, data, {
      params: {
        action: 'start',
      },
    })
      .pipe(
        switchMap(businessProcess => (options?.skipLocationChange
          ? of(businessProcess)
          : this.saveProcessIds(businessProcess))),
      );
  }

  @LoaderEnabled()
  public startBpAndGetData<T extends IForm = IForm>(processName: string, data: any, options: StartBpOptions = {}, skipLoader = SkipPreloader.false): Observable<T> {
    return this.start(processName, data, options, skipLoader)
      .pipe(
        switchMap(businessProcess => this.getFormData<T>(businessProcess.pid, businessProcess.taskId, skipLoader)),
      );
  }

  @showErrorAlert()
  @LoaderEnabled() // skipLoader used for decorator
  public getFormData<T extends IForm = IForm>(pid: string, tid: string, skipLoader = SkipPreloader.false): Observable<T> {
    return this.httpClient.get<T>(`/api/process/pid/${pid}/tid/${tid}/form`);
  }

  @LoaderEnabled()
  public getCurrentBpFormData<T extends IForm = IForm>(): Observable<T> {
    const { pid, tid } = this.getProcessIds();
    return this.getFormData(pid, tid);
  }

  public getProcessIds(): BusinessProcessIds {
    return {
      pid: this.route.snapshot.queryParams.pid,
      tid: this.route.snapshot.queryParams.tid,
    };
  }

  // TODO: maybe use localStorage as store? Pros: don't have async problems like url store
  public saveProcessIds(businessProcess: IBp, url?: string): Observable<IBp> {
    return from(this.router.navigate(
      url ? [url] : [],
      {
        queryParams: {
          pid: businessProcess.pid, tid: businessProcess.taskId,
        },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      },
    ))
      .pipe(switchMap(() => of(businessProcess)));
  }

  public updateProcessTid(tid: string | undefined): Observable<boolean> {
    if (!tid) {
      return of(false);
    }
    return from(this.router.navigate([], {
      queryParams: {
        tid,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    }));
  }

  @showErrorAlert()
  @LoaderEnabled()
  public submit(pid: string, tid: string, data: any): Observable<IBp> {
    return this.httpClient.post<IBp>(`/api/process/pid/${pid}/tid/${tid}/submit`, data)
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess)),
        catchError((error: any) => this.updateProcessTid(error.error?.requested?.tid)
          .pipe(switchMap(() => throwError(error)))),
      );
  }

  @LoaderEnabled()
  public submitCurrentBp(data: any): Observable<IBp> {
    const { pid, tid } = this.getProcessIds();
    return this.submit(pid, tid, data);
  }

  @LoaderEnabled()
  public submitAndGetForm<T extends IForm = IForm>(data: any): Observable<T> {
    const { pid, tid }: BusinessProcessIds = this.getProcessIds();
    return this.submit(pid, tid, data)
      .pipe(
        switchMap(businessProcess => this.getFormData<T>(businessProcess.pid, businessProcess.taskId)),
      );
  }

  @LoaderEnabled()
  public stepBack(currentFormKey: string, prevFormKey: string): Observable<any> {
    const { pid, tid }: BusinessProcessIds = this.getProcessIds();
    return this.httpClient.post(
      `/api/process/pid/${pid}/tid/${tid}/sendSignal`,
      {
        targetForm: prevFormKey,
      },
      {
        params: {
          signal: currentFormKey,
        },
      },
    );
  }

  @LoaderEnabled()
  public sendMessage(pid: string, tid: string, message: string, data: any = {}): Observable<IBp> {
    return this.httpClient.post<IBp>(
      `/api/process/pid/${pid}/tid/${tid}/sendMessage`,
      data,
      {
        params: {
          message,
        },
      },
    )
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess)),
      );
  }

  @LoaderEnabled()
  public sendMessageCurrentBp(message: string, data: any = {}): Observable<IBp> {
    return this.sendMessage(this.getProcessIds().pid, this.getProcessIds().tid, message, data);
  }

  @LoaderEnabled()
  public goToStepAndGetForm<T extends IForm = IForm>(message: string, navigateTo?: string, data?: any): Observable<T> {
    return this.sendMessageCurrentBp(message, data)
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess, navigateTo)),
        switchMap(businessProcess => this.getFormData<T>(businessProcess.pid, businessProcess.taskId)),
      );
  }
}
