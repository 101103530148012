<div
  data-qa-block="select"
  class="drop-down-list"
  [ngClass]="{ 'drop-down-visible': isDropdownListVisible }"
>
  <button
    (click)="updateVisibility()"
    [ngClass]="{ 'drop-down__disabled': disabled }"
    class="drop-down__button"
    data-qa-button="toggle-list-visibility"
  >
    <span
      *ngIf="placeholder && !buttonText"
      class="ui-kit-grey-text"
      data-qa="button-placeholder"
      >{{ placeholder | translate }}</span
    >
    <span data-qa="selected-item">{{ buttonText | translate }}</span>
    <svg-icon
      [key]="isDropdownListVisible ? 'triangle-up' : 'triangle-down'"
      class="drop-down__arrow"
    ></svg-icon>
  </button>

  <div class="drop-down-container">
    <div class="drop-down-input">
      <input
        type="text"
        class="drop-down--input"
        #search
        placeholder="{{ placeholder | translate }}"
        [(ngModel)]="searchText"
        [taxAutofocus]="isDropdownListVisible"
        (input)="onInput()"
        data-qa-input="select-search"
      />
    </div>
    <ul class="drop-down-content" [class.scrollable]="scrollable" data-qa="select-list-items">
      <li
        class="dd-li drop-down-li"
        data-qa="select-item"
        *ngFor="
          let item of data | filter: searchText | slice: 0:(isInfinite ? 10000 : 15);
          trackBy: utilService.trackByFn
        "
        [ngClass]="{ 'drop-down__selected-item': item.title === buttonText }"
        (click)="selectItem(item)"
      >
        <span class="drop-down__item" translate data-qa="select-item-text">{{ item.title }}</span>
        <span *ngIf="item.child && item.child.length">
          <svg-icon key="arrow-back" class="arrow-right"></svg-icon>
        </span>
        <span *ngIf="item.label" class="label" data-qa="select-item-label">
          {{ item.label | translate }}
        </span>
        <div class="drop-down__child" *ngIf="item.child && item.child.length > 0">
          <ul data-qa-block="child-list">
            <li
              class="dd-li"
              *ngFor="let i of item.child"
              (click)="selectItem(i)"
              translate
              data-qa="child-list-item"
            >
              {{ i.title }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
