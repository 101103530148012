/** Данные по ККМ (кассе) */
export interface IKkm {
  ctoEvents?: any[]; /** Дата добавления ККМ в БД */
  createdAt: number; /** Причина отклонения регистрации ККМ */
  declineReason?: string; /** */
  fnsKkmId?: string; /** Идентификатор ККМ */
  id: number; /** Внутреннее название ККМ */
  internalName: string; /** Данные по смене */
  lastShift?: { /** */
    fiscalDocumentCount?: number; /** Идентификатор ККМ */
    kkmId: string; /** */
    lastTransaction?: number; /** Дата последнего нахождения в сети */
    onlineDate?: number; /** Сумма возвратов за смену */
    returns: number; /** Сумма продаж за смену */
    sells: number; /** */
    shiftNumber: number; /** Номер смены */
    shiftState: number; /** Дата последнего обновления */
    updateDate?: number;
  }; /** Модель ККМ */
  model: string; /** Онлайн-статус */
  onlineStatus: number; /** Идентификатор организации */
  orgId: number; /** Статус регистрации */
  regStatus: RegStatus; /** Дата смены регистрационного статуса */
  regStatusChangedAt?: number; /** статус кассы в системе биллинга */
  billingStatus?: BillingStatus; /** Дата смены биллингового статуса */
  billingStatusChangedAt?: number; /** Идентификатор торговой точки */
  retailPlaceId: number; /** Серийный номер ККМ */
  serialNumber: string; /** Идентификатор тарифа */
  tariffId?: number; /** Тип устройства: 0 - стационарное устройство, 1 - мобильное устройство, 2 - ККТ входит в состав платежного терминала (банкомата) */
  type?: 0 | 1 | 2; /** Год выпуска ККМ */
  year?: number; /** Данные о подключении */
  network?: { /** Идентификатор подключения */
    id: string | null; /** Название подключения */
    name: string; /** Тип подключения (0 - беспроводное, 1 - проводное, -1 - неизвестный тип подключения) */
    networkType: 0 | 1 | -1; /** Тариф */
    tariff?: string;
  }; /** */
  billingErrorCode?: string; /** */
  tariffPrice?: number;
}

export interface KkmsFilterState {
  billingStatuses: BillingStatus[];
  regStatuses: RegStatus[];
  shiftState: ShiftState;
}

export interface KkmsPaginationState {
  pagination: {
    from: number;
    to: number;
  };
}

export interface FilterKkmsRequest extends KkmsFilterState, KkmsPaginationState {
  sortField?: 'kkmCount' | 'title' | 'updatedAt';
  sortingDirection?: 'asc' | 'desc';
  type: KkmType;
}

export interface RetailPlacesWithKkmsRequest extends FilterKkmsRequest{
  criteria?: string;
}

/** Статус регистрации ККМ */
export enum RegStatus { /** Сохранена в ЛК */
  Saved = 0, /** Договор подписан */
  ContractIsSigned = 1, /** Ожидает регистрацию в КГД */
  SubmittedForRegistration = 2, /** Ошибка регистрации в КГД */
  RegistrationError = 3, /** Зарегистрирована в КГД */
  Registered = 4, /** Ошибка перерегистрации в КГД */
  ReregistrationError = 5, /** Ожидает снятие с учета в КГД */
  RequestToDeregisterHasBeenSent = 6, /** Ошибка снятия с учета в КГД */
  ErrorOnDeregistration = 7, /** Снята с учета в КГД */
  Deregistered = 8, /** Списана/ Снята с учета */
  Decommissioned = 9, /** Ожидает перерегистрацию в КГД */
  SentForReregistration = 10, /** Неизвестный статус */
  UnknownStatus = -1,
}

/** Статус подписки */
export enum BillingStatus {/** Подписка подключена */
  Registered = 0, /** Подписка активна */
  Activated = 1, /** Подписка отключена */
  Deactivated = 2, /** Подписка не выбрана */
  WithdrawnFromRegistration = 3, /** Отключена за неуплату */
  Blocked = 4,
  UnknownStatus = -1,
}

export enum KkmType {
  Stationary = 0,
  Mobile = 1,
  Terminal = 2,
  Web = 3,
  UnknownType = -1,
}

/** Статус смены */
export enum ShiftState { /** Смена открыта */
  Closed = 1, /** Смена закрыта */
  Opened = 5,
  Unknown = -1,
}

type VpnStatus = 0 | 1 | 2 | 3;

/** Данные по торговой точке */
export interface RetailPlace { /** Адрес */
  address: string; /** Квартира/офис/апартаменты */
  apartment: string; /** Область */
  area: string; /** Город */
  city: string; /**  */
  flags: {
    isKkmsLoaded: boolean;
    addressInvalidKey?: string;
  }; /** Дом */
  house: string; /** Корпус */
  housing: string; /** Идентификатор торговой точки */
  id: number; /**  */
  kkms: IKkm[]; /** Почтовый код */
  postalCode: string; /** Код региона */
  regionCode: string; /** Населённый пункт */
  settlement: string; /** Улица */
  street: string; /** Название */
  title: string; /** Статус ВПН */
  vpnStatus: VpnStatus;
}

export interface IRetailPlace extends RetailPlace { // Количество ККМ в сети
  kkmsOnlineCount: number; // Идентификатор канала
  channelId?: number;
}

export interface ITotalCount {
  activatedCount: number;
  totalCount: number;
  billingStatusCounts?: IBillingStatusCount[];
  regStatusCounts?: IRegStatusCount[];
  shiftStateCounts?: IShiftStatusCount[];
}

interface IBillingStatusCount {
  id: BillingStatus;
  count: number;
}

interface IRegStatusCount {
  id: RegStatus;
  count: number;
}

interface IShiftStatusCount {
  id: ShiftState;
  count: number;
}

export interface KkmDeregisterReason {
  [key: string]: string;
}
