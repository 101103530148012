import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'tax-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  /**
   Проблема.

   При открытии страницы, фокус может быть автоматически установлен на инпут. Если нажать на какую-нибудь кнопку/ссылку, то под инпутом может появится сообщение об ошибке.
   В результате из-за того, что кнопка/ссылка сместиться вниз,- не сработает событие click, т.к. событие mousedown вызовется на одном элементе, а mouseup на другом.

   Решение.

   Для этого кейса и написана функция. Вручную запустим событие click на элементе, на котором он должен был вызваться
   */
  @HostListener('mousedown', ['$event'])
  handleClickEvent(mousedownEvent: MouseEvent): void {
    window.addEventListener('mouseup', (mouseupEvent: MouseEvent): void => {
      const isMousePositionNonChanged = mousedownEvent.clientX === mouseupEvent.clientX && mousedownEvent.clientY === mouseupEvent.clientY;
      const isTargetChanged = mousedownEvent.target !== mouseupEvent.target;
      if (isMousePositionNonChanged && isTargetChanged) {
        const clickEvent = new Event('click');
        mousedownEvent.target?.dispatchEvent(clickEvent);
      }
    }, {
      once: true,
    });
  }
}
