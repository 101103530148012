<tax-form-control-wrapper
  *ngIf="inputControl"
  [clearable]="clearable"
  [disabled]="disabled"
  [descriptionText]="descriptionText"
  [descriptionRightSide]="descriptionRightSide"
  [errorType]="errorType"
  [errorText]="errorText"
  [helpText]="helpText"
  [id]="id"
  [inputActionButton]="inputActionButton"
  [inputControl]="inputControl"
  [isShowTooltipError]="isShowTooltipError"
  [labelText]="labelText"
  [layoutColumns]="layoutColumns"
  [ngControl]="ngControl"
  [showSuffixButton]="true"
  [topActionButton]="topActionButton"
  [width]="width"
  (cleanEvent)="cleanEvent.emit()"
>
  <input
    [id]="id"
    [formControl]="inputControl"
    [attr.type]="isPasswordVisible ? 'text' : 'password'"
    [style]="inputStyles"
    [taxAutofocus]="autofocus"
    [tabindex]="tabindex"
    (mouseover)="showTooltipError()"
    (mouseout)="hideTooltipError()"
    [autocomplete]="autocomplete ? 'on' : 'new-password'"
    data-qa="input"
  />
  <button type="button" class="suffix-button" (click)="togglePasswordVisibility()">
    <i class="icon eye-icon" aria-hidden="true" [class.clean]="!inputControl.value"></i>
  </button>
</tax-form-control-wrapper>
