import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding, inject,
  Input, OnChanges,
  Output, SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, NgControl, Validators } from '@angular/forms';
import { ErrorType } from '../form.model';

@Component({
  selector: 'tax-form-control-wrapper',
  templateUrl: 'form-control-wrapper.component.html',
  styleUrls: ['./form-control-wrapper.component.styl'],
})

export class FormControlWrapperComponent implements OnChanges {
  // w3c input attributes
  @Input() disabled = false;

  // w3c global attributes
  @Input() id = String(Math.floor(Math.random() * Date.now()));

  // component's inputs
  @Input() clearable = false;
  @Input() descriptionText?: string;
  @Input() descriptionRightSide?: TemplateRef<any>;
  @Input() errorType: ErrorType = 'bottom';
  @Input() errorText?: string; // Используется для шаблонных форм и ошибок, пришедших извне (с сервера, например)
  @Input() helpText?: string;
  @Input() inputActionButton: TemplateRef<any> | null = null;
  @Input() inputControl?: AbstractControl;
  @Input() isShowTooltipError = false;
  @Input() labelText?: string;
  @Input() layoutColumns: 1 | 2 = 1;
  @Input() ngControl?: NgControl;
  @Input() patternErrorText?: string;
  @Input() prefix?: string;
  @Input() showSuffixButton = false;
  @Input() topActionButton: TemplateRef<any> | null = null;
  @Input() width?: string;
  @Input() set validateEvent(event: null | unknown) {
    this.updateRequiredFlag();
  }

  @Output() cleanEvent: EventEmitter<void> = new EventEmitter<void>(); // Используется для template driven forms

  @HostBinding('class.invalid') get hostClass(): boolean {
    const isInputInvalid = Boolean(this.inputControl?.invalid) || Boolean(this.ngControl?.invalid); // проверяем inputControl для template driven форм, а reactiveControl для реактивных
    const isInputDirty = Boolean(this.inputControl?.dirty) || Boolean(this.inputControl?.touched);

    this.showError = isInputInvalid && isInputDirty;
    return this.showError;
  }

  isRequired = false;
  showError = false;

  private cdr = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    const isInputControlChanged = 'inputControl' in changes && changes.inputControl.previousValue !== changes.inputControl.currentValue;
    const isNgControlChanged = 'ngControl' in changes && changes.ngControl.previousValue !== changes.ngControl.currentValue;
    if (isInputControlChanged || isNgControlChanged) {
      this.updateRequiredFlag();
    }
  }

  cleanInput(): void {
    this.inputControl?.setValue(null);
    this.cleanEvent.emit();
  }

  getError(): string {
    if (this.ngControl?.errors?.errorMessage || this.inputControl?.errors?.errorMessage) {
      return this.ngControl?.errors?.errorMessage || this.inputControl?.errors?.errorMessage;
    }
    if (this.ngControl?.errors?.required === true) {
      return 'required.field';
    }
    if (this.ngControl?.errors?.pattern && this.patternErrorText) {
      return this.patternErrorText;
    }

    if (this.errorText) {
      return this.errorText;
    }

    if (this.inputControl?.errors?.mask.requiredMask) {
      return this.inputControl?.errors?.mask.requiredMask;
    }

    return '';
  }

  updateRequiredFlag(): void {
    this.isRequired = this.inputControl?.hasValidator(Validators.required) || Boolean(this.ngControl?.control?.hasValidator(Validators.required));
    this.cdr.detectChanges();
  }
}
