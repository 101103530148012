import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TagKeyValue, TagsFilter } from '../kkms/kkms-page-filter/kkms-page-filter.model';
import { UtilsService } from '../../core/services/utils.service';
import { AccountService, INotification, INotificationFilters } from '../../core/services/account.service';

interface UiINotification extends INotification {
  selected?: boolean;
  selectedActive?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'tax-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.styl'],
})
export class NotificationsPageComponent implements OnInit {
  searchField = '';
  dateFrom = new Date();
  dateTo = new Date();
  isShowDisabled = true;
  tags: TagsFilter = {
    today: {
      count: 0, selected: false, name: 'account_payment.today',
    },
    week: {
      count: 0, selected: false, name: 'account_payment.week',
    },
    monthly: {
      count: 0, selected: true, name: 'account_payment.monthly',
    },
  };

  objectInOriginalOrder = this.utilsService.objectInOriginalOrder;
  notifications: UiINotification[] = [];
  hasUnreadNotifications = false;
  isNoNotifications = false;
  isAllSelected = false;
  filters: INotificationFilters = {
    fromDate: 0, toDate: 0,
  };

  constructor(
    private utilsService: UtilsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.filters.fromDate = this.dateFrom.setMonth(this.dateFrom.getMonth() - 1).valueOf();
    this.filters.toDate = this.dateTo.setHours(23, 59, 59).valueOf();
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.getFilterNotifications(params.notificationId);
    });
  }

  // onSearch() { }

  getFilterNotifications(id?: number): void {
    this.accountService.getFilteredNotifications(this.filters).pipe(untilDestroyed(this)).subscribe((data) => {
      this.notifications = data.notifications.map(m => ({
        ...m, selectedActive: m.id === Number(id),
      }));
      this.hasUnreadNotifications = Boolean(data.unreadCount);
      this.isNoNotifications = !data.totalCount;
      if (this.isAllSelected) { this.selectAll(); }
    });
  }

  inputDate(e: MatDatepickerInputEvent<any, any>, type: string): void {
    Object.values(this.tags).forEach(tag => tag.selected = false);
    this.isShowDisabled = false;
    type === 'dateTo' ? this.dateTo = new Date(e.value) : this.dateFrom = e.value;
    this.filters.fromDate = this.dateFrom.valueOf();
    this.filters.toDate = this.dateTo.setHours(23, 59, 59).valueOf();
  }

  selectTag(tag: TagKeyValue): void {
    if (!tag.value.selected) {
      Object.values(this.tags).forEach(tag => tag.selected = false);
    }
    tag.value.selected = !tag.value.selected;
    this.isShowDisabled = true;
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.filters.toDate = this.dateTo.setHours(23, 59, 59).valueOf();
    if (tag.key === 'today') {
      this.filters.fromDate = moment(this.dateFrom).startOf('day').valueOf();
    }
    if (tag.key === 'monthly') {
      this.filters.fromDate = this.dateFrom.setMonth(this.dateFrom.getMonth() - 1).valueOf();
    }
    if (tag.key === 'week') {
      this.filters.fromDate = this.dateFrom.setDate(this.dateFrom.getDate() - 7).valueOf();
    }
    this.getFilterNotifications();
  }

  selectAll(): void {
    this.notifications.forEach((n) => {
      if (!n.isRead) n.selected = this.isAllSelected;
    });
  }

  isSomeSelected(): boolean {
    return this.notifications.some(n => n.selected);
  }

  setSelectAll(): void {
    this.isAllSelected = this.notifications.filter(f => !f.isRead).every(e => e.selected);
  }

  changeReadStatus(n?: UiINotification): void {
    let body = [];
    if (n) {
      body = [{
        id: n.id, notificationType: n.notificationType,
      }];
    } else {
      body = this.notifications.filter(n => n.selected).map(m => ({
        id: m.id, notificationType: m.notificationType,
      }));
    }
    this.accountService.setNotificationsReadStatus(body).pipe(untilDestroyed(this)).subscribe(() => {
      this.getFilterNotifications();
      this.accountService.reloadNotificationsEvent$.next();
    });
  }
}
