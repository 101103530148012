import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

interface NavigateParams {
  commands: any[];
  extras: NavigationExtras;
}

/*
Используется чтобы разруливать случаи, когда router.navigate вызывается в разных компонентах почти одновременно.
Поведение в angular такое, что второй вызов может не позволить завершится первому. При использовании этого сервиса
такого поведения не происходит.

Редкие случаи, когда сервис нужен - это когда на странице есть пагинация (устанавливает page=...) и фильтр (устанавливает свои
query параметры в адресную строку браузера).
 */
@Injectable({
  providedIn: 'root',
})
export class LocationService {
  isNavigationProcessActive = false;
  navigateQueue: NavigateParams[] = [];

  constructor(
    private router: Router,
  ) {
    this.setLocationOnNavigationEnd();
  }

  navigate(commands: any[], extras: NavigationExtras): void {
    if (!this.isNavigationProcessActive) {
      this.router.navigate(commands, extras);
    } else {
      this.navigateQueue.push({
        commands, extras,
      });
    }
  }

  private setLocationOnNavigationEnd(): void {
    this.router.events
      .pipe(
        tap(() => {
          this.isNavigationProcessActive = true;
        }),
        filter(event => (event instanceof NavigationEnd)),
        tap(() => {
          this.isNavigationProcessActive = false;
        }),
        filter(() => this.navigateQueue.length > 0),
      )
      .subscribe(() => {
        const locationData = this.navigateQueue.shift() as NavigateParams;
        this.router.navigate(locationData.commands, locationData.extras);
      });
  }
}
