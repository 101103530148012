<div
  class="drop-down-list"
  [ngClass]="{ 'drop-down-visible': isVisible, drop_down: data.type === 'bank' }"
>
  <div (click)="toggleVisibility()" class="drop-down__button">
    <span data-qa-dropdown-list="selected-item">{{ selectedItemText | translate }}</span>
    <svg-icon [key]="isVisible ? 'triangle-up' : 'triangle-down'" class="svg_style"></svg-icon>
  </div>
  <div class="drop-down-container">
    <div
      class="drop-down-content"
      [ngClass]="{ drop_down: data.type === 'bank' }"
      data-qa-dropdown-list="list-items"
    >
      <p
        *ngFor="let item of data; trackBy: utilService.trackByFn"
        [ngClass]="{ 'drop-down__selected-item': item.name === selectedItemText }"
        (click)="selectItem(item)"
        data-qa-dropdown-list="item"
      >
        <span data-qa-dropdown-list="item-text">{{
          data.type === 'bank'
            ? item.bic + ' - ' + (item.name | translate)
            : (item.name | translate)
        }}</span>
      </p>
    </div>
  </div>
</div>
