import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class IsmetInterceptor implements HttpInterceptor {
  private readonly AUTHORIZATION_HEADER_NAME = 'Authorization';
  private readonly ORG_IIN_BIN_HEADER_NAME = 'X-ORG-IIN-BIN';

  constructor(
    private cookieService: CookieService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request: HttpRequest<any> = this.setRequestHeaders(req);

    return next.handle(request);
  }

  private setRequestHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const authHeader: string = this.cookieService.get(this.AUTHORIZATION_HEADER_NAME);
    const orgIinBin: string = this.cookieService.get(this.ORG_IIN_BIN_HEADER_NAME);

    let updatedRequest: HttpRequest<any> = req.clone({});

    if (authHeader && orgIinBin) {
      updatedRequest = updatedRequest.clone({
        setHeaders: {
          [this.AUTHORIZATION_HEADER_NAME]: authHeader,
          [this.ORG_IIN_BIN_HEADER_NAME]: orgIinBin,
        },
      });
    }

    return updatedRequest;
  }
}
