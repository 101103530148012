export enum Pages {
  Kkms = '/main/kkms',
  Analytics = '/main/analytics',
  BillingAccount = '/main/account',
  Groups = '/main/groups',
  Users = '/main/users',
  Documents = '/main/documents',
  Organization = '/main/organization',
  Tariffs = '/main/tariffs',
  Subscriptions = '/main/subscriptions',
  Cto = '/main/cto',
  Help = '/main/help',
}
