import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderEnabled } from './loader.service';
import { Permission } from './modules-permissions.model';
import { Base64String } from '../../shared/services/signature-nca-common-utils.service';
import { BpService } from './bp.service';
import { RetailPlace } from '../models/kkm.model';
import { showErrorAlert, successAlert } from '../../shared/components/alerts/alert.decorator';
import { Pages } from '../../system/pages';

@Injectable()
export class RetailPlaceService {
  constructor(
    private bpService: BpService,
    private httpClient: HttpClient,
    private router: Router,
  ) { }

  public getRegion(region: string): Observable<any> {
    const params = new HttpParams()
      .set('region', region);
    return region ? this.httpClient.get('/api/regions', {
      params,
    }) : this.httpClient.get('/api/regions?region=');
  }

  public getRetailPlaces(): Observable<RetailPlace[]> {
    return this.httpClient.get<RetailPlace[]>('api/kkms/retail-places');
  }

  public getToponyms(areaId: string, city: string): Observable<any> {
    const params = new HttpParams()
      .set('city', city);
    return city ? this.httpClient.get(
      `/api/ats/${areaId}/toponyms`,
      {
        params,
      },
    ) : this.httpClient.get(`/api/ats/${areaId}/toponyms?city=`);
  }

  public getToponym(localityId: string): Observable<any> {
    return this.httpClient.get(`/api/ats/${localityId}`);
  }

  public getGeonims(localityId: string, street: string): Observable<any> {
    const params = new HttpParams()
      .set('street', street);
    return this.httpClient.get(`/api/ats/${localityId}/geonims`, {
      params,
    });
  }

  // Получить ссылку на заявление на подключение vpn в pdf
  getVpnConnectDocumentLink(pid?: string): string {
    const processId = pid || this.bpService.getProcessIds().pid;
    return `/api/process/pid/${processId}/vpn-connect-application`;
  }

  // Получить заявление на подключение к vpn в base64
  getVpnConnectDocumentBase64(pid?: string): Observable<Base64String> {
    const processId = pid || this.bpService.getProcessIds().pid;
    return this.httpClient.get(`/api/process/pid/${processId}/vpn-connect-application/base64`, {
      responseType: 'text',
    });
  }

  @LoaderEnabled()
  public setNewTitle(id: number, title: string): Observable<any> {
    const params = new HttpParams()
      .set('newTitle', title);
    return this.httpClient.post(`/api/kkms/retail-place/${id}`, {}, {
      params,
    });
  }

  @showErrorAlert()
  @LoaderEnabled()
  public deleteRetailPlace(id: number): Observable<any> {
    return this.httpClient.delete(`/api/kkms/retail-place/${id}`)
      .pipe(
        mergeMap(() => this.router.navigate([Pages.Kkms])),
        successAlert({
          message: 'kkms.retail.place.delete.success',
        }),
      );
  }

  isFullRetailPlaceAccess(permissions: Permission[]): boolean {
    return permissions.includes('retail.*');
  }
}
