import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { NotificationsPageComponent } from './notifications-page/notifications-page.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
      },
      {
        path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'documents', loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
      },
      {
        path: 'tariffs', loadChildren: () => import('./tariffs/tariffs.module').then(m => m.TariffsModule),
      },
      {
        path: 'subscriptions', loadChildren: () => import('./tariffs/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
      },
      {
        path: 'account', loadChildren: () => import('./payment-account/payment-account.module').then(m => m.PaymentAccountModule),
      },
      {
        path: 'organization', loadChildren: () => import('./organization/organisation.module').then(m => m.OrganisationModule),
      },
      {
        path: 'kkms', loadChildren: () => import('./kkms/kkms.module').then(m => m.KkmsModule),
      },
      {
        path: 'cto', loadChildren: () => import('./cto/cto.module').then(m => m.CtoModule),
      },
      {
        path: 'notifications-page', component: NotificationsPageComponent,
      },
      {
        path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
      },
      {
        path: 'error404',
        loadChildren: () => import('./error-404-page/error-404.module').then(m => m.Error404Module),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {}
