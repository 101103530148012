import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

const ERROR_PREFIX = new HttpContextToken<string>(() => '');

export function errorPrefix(prefix: string): HttpContext {
  return new HttpContext().set(ERROR_PREFIX, prefix);
}

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((data: any) => { // Отлавливаем ошибку, которая могла прийти в ответе с 200 кодом
        if (data?.body?.message || data?.body?.errorCode) {
          const errorPrefix = data.body.message ? `${data.body.message}` : request.context.get(ERROR_PREFIX);
          const error = data.body.errorCode ? data.body.errorCode : '';
          throw new HttpErrorResponse({
            error: {
              message: `${errorPrefix}.${error}`,
            },
            status: HttpStatusCode.BadRequest,
          });
        }
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.router.navigate(['']);
          return EMPTY;
        }

        return throwError(() => error);
      }),
    );
  }
}
