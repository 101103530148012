import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderEnabled } from './loader.service';
import { BillingStatus, FilterKkmsRequest, IKkm, IRetailPlace, ITotalCount, RegStatus, RetailPlacesWithKkmsRequest } from '../models/kkm.model';
import {
  DeregisterReasonLocalizedMessage,
  DeregisterReasons,
} from '../../system/kkms/kkm-operations/deregister-kkm/deregister-kkm.model';
import { AlertsService } from './alerts.service';
import { showErrorAlert } from '../../shared/components/alerts/alert.decorator';

@Injectable({
  providedIn: 'root',
})

export class KkmsService {
  constructor(
    private alertsService: AlertsService,
    private httpClient: HttpClient,
  ) {
  }

  // Получить pdf-договор при подписании регистрации/дерегистрации ККМ
  public getLinkToPdfKKkmChangeStatusDocument(pid: string): string {
    return `/api/process/pid/${pid}/registration-application`;
  }

  // Получить base64 (для подписания изменения статуса ККМ с помощью ЭЦП)
  public getKkmChangeStatusDocument(pid: string): Observable<string> {
    return this.httpClient.get(`/api/process/pid/${pid}/registration-application/base64`, {
      responseType: 'text',
    });
  }

  // Получить заявление на регистрацию в base64
  public getKkmRegistrationDocument(pid: string): Observable<string> {
    return this.httpClient.get(`/api/process/pid/${pid}/tax-registration-application/base64`, {
      responseType: 'text',
    });
  }

  // Получить ссылку на pdf заявление на регистрацию
  public getLinkToPdfKkmRegistrationDocument(pid: string): string {
    return `/api/process/pid/${pid}/tax-registration-application`;
  }

  // Получить список причин для снятия ККМ с учета
  public getDelReasons(): Observable<DeregisterReasons> {
    return this.httpClient.get<DeregisterReasons>('api/kkms/delReasons');
  }

  public getToken(kkmId: string): Observable<any> {
    return this.httpClient.get(`/api/kkms/${kkmId}/reset-token`);
  }

  // Получить причину снятия ККМ с учёта (null в случае отсутствия причины)
  public getDelReason(id: string): Observable<DeregisterReasonLocalizedMessage> {
    return this.httpClient.get<DeregisterReasonLocalizedMessage>(`/api/kkms/delReason?id=${id}`);
  }

  @showErrorAlert()
  public getStatusesCount(): Observable<ITotalCount> {
    return this.httpClient.get<ITotalCount>('/api/kkms/totalcount');
  }

  public getKkmModels(): Observable<string[]> {
    return this.httpClient.get<string[]>('api/kkms/models');
  }

  public getKkmsId(): Observable<number[]> {
    return this.httpClient.get<number[]>('/api/kkms/ids');
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getKkmsByStatus(data: FilterKkmsRequest): Observable<Array<IRetailPlace>> {
    return this.httpClient.post<Array<IRetailPlace>>('/api/kkms/filter-kkms', data);
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getRetailPlacesWithKkms(data: RetailPlacesWithKkmsRequest): Observable<IRetailPlace[]> {
    return this.httpClient.post<IRetailPlace[]>('/api/kkms/search-retail-place-with-kkm', data);
  }

  @showErrorAlert()
  @LoaderEnabled()
  public getKkmsByCriteria(data: any): Observable<Array<IRetailPlace>> {
    return this.httpClient.post<Array<IRetailPlace>>('/api/kkms/search-kkms', data);
  }

  @LoaderEnabled()
  public getKkmsIdByCriteria(data: RetailPlacesWithKkmsRequest): Observable<number[]> {
    return this.httpClient.post<number[]>('/api/kkms/search-kkm-id-with-filter', data);
  }

  @LoaderEnabled()
  public getDeclineReason(id: string): Observable<string> {
    return this.httpClient.get((`/api/kkms/declineReasons?ids=${id}`), {
      responseType: 'text',
    });
  }

  sortKkms(kkms: IKkm[]): IKkm[] {
    const notActiveKkms: IKkm[] = [];
    const workedKkms: IKkm[] = [];
    const notWorkedKkms: IKkm[] = [];
    const notPaidKkms: IKkm[] = [];
    const notRegisteredKkms: IKkm[] = [];
    const deregisteredKkms: IKkm[] = [];
    const preRegisteredKkms: IKkm[] = [];
    const otherKkms: IKkm[] = [];

    kkms.forEach((kkm) => {
      switch (kkm.regStatus) {
        case RegStatus.Registered:
          if (kkm.billingStatus === BillingStatus.Registered) {
            notPaidKkms.push(kkm);
          } else if (kkm.billingStatus === BillingStatus.Activated) {
            kkm.lastShift?.shiftState === 5
              ? workedKkms.push(kkm)
              : notWorkedKkms.push(kkm);
          } else {
            notActiveKkms.push(kkm);
          }
          break;
        case RegStatus.RegistrationError:
        case RegStatus.ReregistrationError:
        case RegStatus.RequestToDeregisterHasBeenSent:
        case RegStatus.ErrorOnDeregistration:
          notRegisteredKkms.push(kkm);
          break;
        case RegStatus.Deregistered:
        case RegStatus.Decommissioned:
          deregisteredKkms.push(kkm);
          break;
        case RegStatus.Saved:
        case RegStatus.ContractIsSigned:
        case RegStatus.SubmittedForRegistration:
        case RegStatus.SentForReregistration:
          preRegisteredKkms.push(kkm);
          break;
        default:
          otherKkms.push(kkm);
      }
    });

    return [...notActiveKkms, ...workedKkms, ...notWorkedKkms, ...notPaidKkms, ...notRegisteredKkms, ...deregisteredKkms, ...preRegisteredKkms, ...otherKkms];
  }

  @LoaderEnabled()
  public throwDeclineReason(id: string): Observable<string> {
    return this.httpClient.get((`/api/kkms/declineReasons?ids=${id}`), {
      responseType: 'text',
    })
      .pipe(
        tap((declineReason) => {
          this.alertsService.add({
            message: declineReason,
          });
        }),
      );
  }
}
