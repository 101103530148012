import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ListModalComponent } from './components/modals/list-modal/list-modal.component';
import { ModuleModalComponent } from './components/modals/modules/modules.component';
import { FilterPipe } from './pipes/filter.pipe';
import { SearchDropDownListComponent } from './components/search-dropdown-list/search-dropdown-list.component';
import { DropDownListComponent } from './components/dropdown-list/dropdown-list.component';
import { EmailConfirmationComponent } from './components/email-confirm/email-confirm.component';
import { CertificateErrorModalComponent } from './components/modals/certificate-error/certificate-modal-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SafePipe } from './pipes/safe-url.pipe';
import { AlertsComponent } from './components/alerts/alerts.component';

import { DialogComponent } from './components/modals/dialog/dialog.component';
import { ConfirmBySmsComponent } from './components/modals/confirm-by-sms/confirm-by-sms.component';
import { FormModule } from './components/form-controls/form.module';
import { DateAsyncPipe } from './pipes/date-async.pipe';
import { OpenSignBySmsModalDirective } from './signature/open-sign-by-sms-modal.directive';
import { OpenCertificateModalDirective } from './signature/open-certificate-modal.directive';
import { KzCurrencyPipe } from './pipes/kz-currency.pipe';
import { TaxDatePipe } from './pipes/tax-date.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { ModalModule } from '../core/modal/modal.module';
import { ScrollbarComponent } from './scrollbar/scrollbar.component';
import { OpenEmailConfirmModalDirective } from './components/email-confirm/open-email-confirm-modal.directive';
import { IconsModule } from './icon/icons.module';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AccessSettingsBlockComponent } from './access-settings-block/access-settings-block.component';
import { SelectedPipe } from './selected.pipe';
import { PreloaderModule } from './preloader/preloader.module';
import { SwitchComponent } from './switch/switch.component';
import { TabsModule } from './tabs/tabs.module';
import { ElementShowDirective } from './directives/elementShow.directive';

const exportedComponents = [
  ListModalComponent,
  ModuleModalComponent,
  SearchDropDownListComponent,
  DropDownListComponent,
  EmailConfirmationComponent,
  CertificateErrorModalComponent,
  LoaderComponent,
  PaginatorComponent,
  AlertsComponent,
  DialogComponent,
  ConfirmBySmsComponent,
  ScrollbarComponent,
  PageHeaderComponent,
  AccessSettingsBlockComponent,
  SwitchComponent,
];

const exportedDirectives = [
  OpenSignBySmsModalDirective,
  OpenCertificateModalDirective,
  OpenEmailConfirmModalDirective,
  ElementShowDirective,
];

const exportedPipes = [
  DateAsyncPipe,
  DateFormatPipe,
  FilterPipe,
  SafePipe,
  KzCurrencyPipe,
  TaxDatePipe,
  DateTimePipe,
  SelectedPipe,
];

const exportedModules = [
  CommonModule,
  TranslateModule,
  FormModule,
  IconsModule,
  PreloaderModule,
  TabsModule,
];

@NgModule({
  imports: [
    ...exportedModules,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    ModalModule,
  ],
  exports: [
    ...exportedModules,
    ...exportedComponents,
    ...exportedDirectives,
    ...exportedPipes,
  ],
  providers: [
  ],
  declarations: [
    ...exportedComponents,
    ...exportedDirectives,
    ...exportedPipes,
  ],
})
export class SharedModule {
}
