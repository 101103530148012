<div data-qa-block="alert" class="snackbar {{ alert?.type }}" *ngFor="let alert of alerts; let i = index">
  <tax-icon class="alert-icon" [name]="alert?.type === 'success' ? 'done' : 'error'"></tax-icon>
  <div>
    <div data-qa="alert-title" class="title {{ alert?.type }}" translate *ngIf="alert?.title !== ''">
      {{ alert?.title }}
    </div>
    <div *ngIf="alert?.message" data-qa="alert-message">{{ alert.message | translate: alert.messageVariables }}</div>
  </div>
  <tax-icon data-qa-button="close-alert" class="close-icon" name="cancel" (click)="close(i)"></tax-icon>
</div>
