<tax-loader [isMainContent]="false"></tax-loader>
<tax-header [isKnownUser]="false"></tax-header>

<div class="restore-container">
  <tax-page-header titleText="user.password.restore.title"></tax-page-header>

  <div class="ui-kit-white-card">
    <div class="ui-kit-white-card__body">
      <form class="card-content ui-kit-rows-layout" (ngSubmit)="submit()">
        <tax-password
          class="input-field"
          [labelText]="'user.password.restore.enter.password'"
          [id]="'password'"
          [formControl]="password"
          [autofocus]="true"
          [tabindex]="1"
          [descriptionText]="'form.error.password.full.text'"
        ></tax-password>

        <tax-password
          class="input-field"
          [labelText]="'user.password.restore.enter.confirmation'"
          [id]="'confirmation'"
          [formControl]="confirmation"
          [tabindex]="2"
        ></tax-password>

        <div class="white-card-bottom">
          <button
            class="button_blue"
            [disabled]="password?.invalid || confirmation?.invalid"
            translate
          >
            button.save
          </button>
        </div>
      </form>

      <div class="card-pic ui-kit-centering-column">
        <svg-icon key="lock"></svg-icon>
      </div>
    </div>
  </div>
</div>
