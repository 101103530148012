<tax-modal-header>email.confirmation</tax-modal-header>

<ng-container *ngIf="activeWindow === 'emailForm'">
  <tax-modal-content>
    <form (ngSubmit)="sendEmail()">
      <p translate>email.field.name</p>
      <input
        class="text_field"
        name="email"
        [(ngModel)]="userEmail"
        #userEmailInput="ngModel"
        placeholder="username@hostname.com"
        taxAutofocus
        taxEmailValidator
      />
      <span class="error-text" *ngIf="userEmailInput.errors && userEmailInput.dirty" translate>
        user.form.incorrect.email
      </span>
      <p class="text_field_description" translate>email.note</p>
    </form>
  </tax-modal-content>

  <tax-modal-footer>
    <button
      [disabled]="userEmailInput.errors"
      class="button_blue ui-kit-align-right"
      translate
      (click)="sendEmail()"
    >
      email.send.key
    </button>
  </tax-modal-footer>
</ng-container>

<ng-container *ngIf="activeWindow === 'confirmEmailForm'">
  <tax-modal-content>
    <form (ngSubmit)="checkSecretKey()">
      <div class="done_text">
        <svg-icon key="done" class="green-icon"></svg-icon>
        {{ 'email.letter.sended' | translate }} {{ userEmail }}
      </div>
      <p translate>email.secret.key</p>
      <tax-input
        name="secretKey"
        [formControl]="secretKey"
        [autofocus]="true"
        placeholder="xxxxxxxx"
        descriptionText="email.fill.input"
        errorType="tooltip"
      ></tax-input>
    </form>
  </tax-modal-content>

  <tax-modal-footer>
    <button class="back-button" type="button" (click)="back()" translate>group.back</button>
    <button
      class="button_blue ui-kit-align-right"
      [disabled]="!secretKey"
      translate
      (click)="checkSecretKey()"
    >
      email.confirm.button
    </button>
  </tax-modal-footer>
</ng-container>
