<aside *ngIf="menuItems | async as menuItems">
  <ul class="nav-list">
    <li *ngFor="let menuItem of menuItems" class="nav-item">
      <a class="nav-item__link" [routerLink]="menuItem.link" routerLinkActive="active">
        <tax-icon [name]="menuItem.icon" class="svg-icon"></tax-icon>
        <span>{{ menuItem.name | translate }}</span>
        <span class="label" *ngIf="menuItem.label">{{ menuItem.label }}</span>
      </a>
    </li>
  </ul>

  <div class="sidebar_bottom_block">
    <div><span translate>sidebar.ads</span></div>
    <a href="https://apps.apple.com/app/tandau/id1463168753" target="_blank">
      <tax-icon name="app-store-monochrome"></tax-icon>
    </a>
    <a href="https://play.google.com/store/apps/details?id=kz.iberica.tandau" target="_blank">
      <tax-icon name="google-play-monochrome"></tax-icon>
    </a>
  </div>
</aside>
